import OrdersService from "../../axios/OrdersService";

export const getMyOrders = (filter) => (dispatch) => {
  // let userId = Auth.getUserId();
  dispatch({
    type: GET_ORDERS_BY_USERID_BEGIN,
  });
  return OrdersService.GetMyOrders(filter)
    .then((res) => {
      console.log(res);
      dispatch({
        type: GET_ORDERS_BY_USERID_SUCCESS,
        payload: res,
      });
      return res;
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: GET_ORDERS_BY_USERID_FAIL,
        payload: {
          error,
        },
      });
      return error;
    });
};

export const GET_ORDERS_BY_USERID_BEGIN = "GET_ORDERS_BY_USERID_BEGIN";
export const GET_ORDERS_BY_USERID_SUCCESS = "GET_ORDERS_BY_USERID_SUCCESS";
export const GET_ORDERS_BY_USERID_FAIL = "GET_ORDERS_BY_USERID_FAIL";
