import { connect } from "react-redux";
import SingleProduct from "./SingleProduct";
import { getProduct } from "../../redux/actions/productAction";
import { getVariantsByProductId } from "../../redux/actions/variantsAction";
import {
  postCart,
  removeProductFromCart,
} from "../../redux/actions/cartAction";

const mapStoreToProps = (state) => ({
  product: state.product.product,
  variants: state.variant.variants,
  currency: state.currency,
  lang: state.lang,
});
const mapDispatchToProps = {
  getProduct,
  getVariantsByProductId,
  postCart,
  removeProductFromCart,
};

export default connect(mapStoreToProps, mapDispatchToProps)(SingleProduct);
