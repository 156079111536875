import { SET_Currency, GET_Currency } from "../actions/CurrencyAction";

const initialState = {
  currency: "dinar",
  loading: false,
  error: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_Currency:
      return {
        ...state,
        currency: action.payload,
      };
    case GET_Currency:
      return {
        ...state,
      };
    default:
      return state;
  }
};
