import axios from "axios";
import https from "https";
import { AxiosRequestConfig } from "axios/index";
import { toast } from "react-toastify";

const authStorage = localStorage.getItem("auth");
const token = authStorage ? JSON.parse(authStorage).token ?? null : null;
const _headers = {
  Authorization: token ? `Bearer ${token}` : null,
};

const configs = {
  baseURL: process.env.REACT_APP_BASE_URL,
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
  headers: _headers,
};
const instance = axios.create(configs);
instance.defaults.headers.common["Content-Type"] =
  "application/json;charset=utf-8";
// instance.defaults.timeout = 2500;

instance.interceptors.response.use(
  (response) => {
    if (!response.data.success) {
      let result = response.data;
      return result;
    }
    return response;
  },
  (error) => {
    console.log(error);
    if (error.response.status === 401) {
      toast("You're unauthorized to do this!", {
        type: "error",
      });
    } else if (error.response.status === 403) {
      toast("Session expired! Please login again.", {
        type: "error",
      });
    } else {
      if (!error.response.data.success) {
        let result = error.response.data;
        console.log(result);
        toast(result.message, {
          type: "error",
        });
      }
    }
  }
);

export default instance;
