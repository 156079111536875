import React, { Component } from "react";
import Heading from "../../components/Heading";
import CartItem from "./CartItem";
import { Button } from "react-bootstrap";
import EmptyCart from "../../assets/images/empty_cart.png";
import CartService from "../../axios/cartService";
import { toast } from "react-toastify";

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      coupon: "",
      recipient_name: "",
      recipient_phone: "",
      recipient_notes: "",
    };
    this.setAddress = this.setAddress.bind(this);
    this.setCoupon = this.setCoupon.bind(this);
    this.setPhone = this.setPhone.bind(this);
    this.setNotes = this.setNotes.bind(this);
    this.setName = this.setName.bind(this);
    this.checkout = this.checkout.bind(this);
  }
  setAddress = (val) => {
    this.setState({ address: val });
  };
  setCoupon = (val) => {
    this.setState({ coupon: val });
  };
  setName = (val) => {
    this.setState({ recipient_name: val });
  };
  setPhone = (val) => {
    this.setState({ recipient_phone: val });
  };
  setNotes = (val) => {
    this.setState({ recipient_notes: val });
  };
  checkout = () => {
    let _products = this.props.cart.cart.products.map((e) => {
      return {
        id: e.id,
        color: e.color,
        count: e.count,
      };
    });
    let payload = {
      payment_method: "cache",
      address: this.state.address,
      coupon: this.state.coupon,
      recipient_name: this.state.recipient_name,
      recipient_phone: this.state.recipient_phone,
      recipient_notes: this.state.recipient_notes,
      products: _products,
    };
    // let token = JSON.parse(localStorage.getItem("auth"))?.token;
    let authStorage = localStorage.getItem("auth");
    let token = null;
    if (authStorage) {
      token = JSON.parse(authStorage).token;
    }
    if (token !== null) {
      CartService.CheckOut(payload)
        .then((resp) => {
          this.props.clearCart();
          this.props.lang.lang == "en"
            ? toast.success("Order has been submitted successfully")
            : toast.success("تم استلام طلبك بنجاح");
        })
        .catch((error) =>
          this.props.lang.lang == "en"
            ? toast.error("An Error Occurred")
            : toast.error("لقد حدث خطأ ما")
        );
    } else {
      CartService.GuestCheckOut(payload)
        .then((resp) => {
          this.props.clearCart();
          this.props.lang.lang == "en"
            ? toast.success("Order has been submitted successfully")
            : toast.success("تم استلام طلبك بنجاح");
        })
        .catch((error) =>
          // this.props.lang.lang == "en"
          //   ? toast.error("An Error Occurred")
          //   : toast.error("لقد حدث خطأ ما")
          {}
        );
    }
  };

  render() {
    const products = this.props.cart.cart?.products;
    const total = this.props.cart.cart?.total;
    const totalQty = this.props.cart.cart?.totalQty;
    const postCart = this.props.postCart;
    console.log(products);
    return (
      <div className="shopping--cart" data-aos="fade-up">
        <div className="container">
          <div className="shopping--cart--container">
            <div className="row ">
              <Heading
                title={
                  this.props.lang.lang == "en"
                    ? "Your Shopping Cart"
                    : "سلة المنتجات الخاصة بك"
                }
                data-aos="fade-up"
              />
            </div>
            <div style={{ height: 30 }}></div>
            <CartItem
              currency={this.props.currency.currency}
              lang={this.props.lang.lang}
              items={products || {}}
              handleClick={this.props.postCart}
              removeProduct={this.props.removeProductFromCart}
            />
            {products !== undefined &&
            products !== null &&
            products.length > 0 ? (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <h3 style={{ textAlign: "center" }}>
                  {this.props.lang.lang == "en" ? `Total: ` : "المجموع: "}
                  <span style={{ color: "#FE4C50" }}>
                    {/* {this.props.currency.currency == "dollar"
                      ? `${total} $`
                      : `${(total * 250.27).toFixed(2)} YRI`} */}
                    {`${total} ${
                      this.props.lang.lang === "en" ? "Dinar" : "دينار"
                    }`}
                  </span>
                  {/* {this.props.lang.lang == "ar" && ` المجموع `} */}
                </h3>
                <div className="row">
                  <div
                    className="col-12"
                    style={{
                      textAlign:
                        this.props.lang.lang === "en" ? "left" : "right",
                      marginBottom: "20px",
                    }}
                  >
                    {" "}
                    <label className="form-label" style={{ fontSize: "14px" }}>
                      {this.props.lang.lang == "en" ? `Address` : " العنوان "}
                    </label>
                    <input
                      className="form-control"
                      onChange={(event) => this.setAddress(event.target.value)}
                    />
                  </div>
                  <div
                    className="col-12"
                    style={{
                      textAlign:
                        this.props.lang.lang === "en" ? "left" : "right",
                      marginBottom: "20px",
                    }}
                  >
                    {" "}
                    <label className="form-label" style={{ fontSize: "14px" }}>
                      {this.props.lang.lang == "en"
                        ? `Recipient name`
                        : " اسم المستلم "}
                    </label>
                    <input
                      className="form-control"
                      onChange={(event) => this.setName(event.target.value)}
                    />
                  </div>
                  <div
                    className="col-12"
                    style={{
                      textAlign:
                        this.props.lang.lang === "en" ? "left" : "right",
                      marginBottom: "20px",
                    }}
                  >
                    {" "}
                    <label className="form-label" style={{ fontSize: "14px" }}>
                      {this.props.lang.lang == "en"
                        ? `Phone number`
                        : " رقم الهاتف "}
                    </label>
                    <input
                      className="form-control"
                      onChange={(event) => this.setPhone(event.target.value)}
                    />
                  </div>
                  <div
                    className="col-12"
                    style={{
                      textAlign:
                        this.props.lang.lang === "en" ? "left" : "right",
                      marginBottom: "20px",
                    }}
                  >
                    {" "}
                    <label className="form-label" style={{ fontSize: "14px" }}>
                      {this.props.lang.lang == "en" ? `Notes` : " ملاحظات "}
                    </label>
                    <input
                      className="form-control"
                      onChange={(event) => this.setNotes(event.target.value)}
                    />
                  </div>
                  <div
                    className="col-12"
                    style={{
                      textAlign:
                        this.props.lang.lang === "en" ? "left" : "right",
                    }}
                  >
                    {" "}
                    <label className="form-label" style={{ fontSize: "14px" }}>
                      {this.props.lang.lang == "en" ? `Coupon` : "الكوبون"}
                    </label>
                    <input
                      className="form-control"
                      onChange={(event) => this.setCoupon(event.target.value)}
                    />
                  </div>
                </div>
                <hr />
                <Button
                  onClick={this.checkout}
                  variant="danger"
                  size="sm"
                  style={{ marginTop: 30 }}
                >
                  {this.props.lang.lang == "en"
                    ? ` Confirm Checkout`
                    : "تأكيد الطلب"}
                </Button>
              </div>
            ) : (
              <div style={{ textAlign: "center" }}>
                <img
                  src={EmptyCart}
                  alt=""
                  style={{ maxHeight: 400, maxWidth: 400 }}
                  className="img-fluid"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Cart;
