import {
  getAllProducts,
  applyFilters,
} from "../../redux/actions/productAction";
import { getAllCategories } from "../../redux/actions/categoryAction";
import { connect } from "react-redux";
import Category from "./Category";
import {
  postCart,
  removeProductFromCart,
} from "../../redux/actions/cartAction";

const mapStoreToProps = (state) => ({
  categories: state.category.categories,
  products: state.product.products,
  productLoading: state.product.loading,
  loading: state.category.loading,
  lang: state.lang,
  currency: state.currency,
  active: state.product.active,
  pageIndex: state.product.pageIndex,
  pageLength: state.product.pageLength,
  search: state.product.search,
  cart: state.cart.cart,
});
const mapDispatchToProps = (dispatch) => ({
  getAllCategories: () => dispatch(getAllCategories()),
  getAllProducts: (filter) => dispatch(getAllProducts(filter)),
  postCart: (productId, count, color, price, image, en_name, ar_name) =>
    dispatch(postCart(productId, count, color, price, image, en_name, ar_name)),
  removeProductFromCart: (productId, _color) =>
    dispatch(removeProductFromCart(productId, _color)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(Category);
