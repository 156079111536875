import api from "./API";

export default class OrdersService {
  static async GetMyOrders(filter) {
    return api
      .get("/my-orders", { params: filter })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw error;
      });
  }
}
