import React from "react";

const ProductGridSkeleton = () => {
  return (
    <>
      <div
        class="col-lg-3 col-md-4 col-sm-6 col-xs-12"
        style={{ paddingBottom: "3em" }}
      >
        <div className="skeleton"></div>
      </div>
      <div
        class="col-lg-3 col-md-4 col-sm-6 col-xs-12"
        style={{ paddingBottom: "3em" }}
      >
        <div className="skeleton"></div>
      </div>
      <div
        class="col-lg-3 col-md-4 col-sm-6 col-xs-12"
        style={{ paddingBottom: "3em" }}
      >
        <div className="skeleton"></div>
      </div>
      <div
        class="col-lg-3 col-md-4 col-sm-6 col-xs-12"
        style={{ paddingBottom: "3em" }}
      >
        <div className="skeleton"></div>
      </div>
      <div
        class="col-lg-3 col-md-4 col-sm-6 col-xs-12"
        style={{ paddingBottom: "3em" }}
      >
        <div className="skeleton"></div>
      </div>
      <div
        class="col-lg-3 col-md-4 col-sm-6 col-xs-12"
        style={{ paddingBottom: "3em" }}
      >
        <div className="skeleton"></div>
      </div>
      <div
        class="col-lg-3 col-md-4 col-sm-6 col-xs-12"
        style={{ paddingBottom: "3em" }}
      >
        <div className="skeleton"></div>
      </div>
      <div
        class="col-lg-3 col-md-4 col-sm-6 col-xs-12"
        style={{ paddingBottom: "3em" }}
      >
        <div className="skeleton"></div>
      </div>
      <div
        class="col-lg-3 col-md-4 col-sm-6 col-xs-12"
        style={{ paddingBottom: "3em" }}
      >
        <div className="skeleton"></div>
      </div>
      <div
        class="col-lg-3 col-md-4 col-sm-6 col-xs-12"
        style={{ paddingBottom: "3em" }}
      >
        <div className="skeleton"></div>
      </div>
    </>
  );
};

export default ProductGridSkeleton;
