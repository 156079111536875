import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import "./style.css";
import jumpTo from "../../modules/Navigation";
import { BASE_URL_SHORT } from "../../base";
import InfoCard from "./info-card";
class SingleOrder extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Modal
        {...this.props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="orderModal"
        className="modal fade login"
      >
        <Modal.Body
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 6,
            borderRadius: "12px",
            border: "1px solid",
            borderColor: "#2B205E",
            padding: "10px",
            boxShadow: "1px 1px 3px 3px #2B205E",
            direction: this.props.lang === "en" ? "ltr" : "rtl",
            float: this.props.lang === "en" ? "left" : "right",
            width: "100%",
            maxHeight: "90vh",
            overflowY: "auto",
          }}
        >
          <div className="modal--close--button" onClick={this.props.onHide}>
            <i className="fas fa-times"></i>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-12 col-md-4">
                <InfoCard
                  title={
                    this.props.lang === "en" ? "Order info" : "معلومات الطلب"
                  }
                  elements={[
                    {
                      title: this.props.lang === "en" ? "Status" : "الحالة",
                      value: (
                        <span
                          style={{
                            border: "1px solid",
                            borderColor: this.props.order.order_status.color,
                            color: this.props.order.order_status.color,
                            padding: "8px",
                            borderRadius: "6px",
                            boxShadow: `0px 0px 1px 1px ${this.props.order.order_status.color}`,
                          }}
                        >
                          {this.props.lang == "en"
                            ? this.props.order.order_status.en_name
                            : this.props.order.order_status.ar_name}
                        </span>
                      ),
                    },
                    {
                      title:
                        this.props.lang === "en" ? "Date" : "تاريخ الإنشاء",
                      value: this.props.order.date,
                    },
                    {
                      title:
                        this.props.lang === "en"
                          ? "Payment method"
                          : "آلية الدفع",
                      value: this.props.order.payment_method,
                    },
                  ]}
                  lang={this.props.lang}
                />
              </div>
              <div class="col-12 col-md-4">
                <InfoCard
                  title={
                    this.props.lang === "en"
                      ? "Address info"
                      : "معلومات العنوان"
                  }
                  elements={[
                    {
                      title: this.props.lang === "en" ? "Address" : "العنوان",
                      value: this.props.order.address,
                    },
                    {
                      title: this.props.lang === "en" ? "Location" : "الموقع",
                      value:
                        this.props.order.latitude && this.props.order.longitude
                          ? `${this.props.order.latitude} , ${this.props.order.longitude}`
                          : "",
                    },
                  ]}
                  lang={this.props.lang}
                />
              </div>
              <div class="col-12 col-md-4">
                <InfoCard
                  title={
                    this.props.lang === "en"
                      ? "Recipient info"
                      : "معلومات المستلم"
                  }
                  elements={[
                    {
                      title: this.props.lang === "en" ? "Name" : "الاسم",
                      value: this.props.order.recipient_name,
                    },
                    {
                      title:
                        this.props.lang === "en"
                          ? "Phone number"
                          : "رقم الهاتف",
                      value: this.props.order.recipient_phone,
                    },
                    {
                      title:
                        this.props.lang === "en"
                          ? "Recipient notes"
                          : "ملاحظات المستلم",
                      value: this.props.order.recipient_notes,
                    },
                  ]}
                  lang={this.props.lang}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 15,
              flexWrap: "wrap",
              padding: 15,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {this.props.order.items.map((item, index) => (
              <div
                className="product-item men"
                style={{
                  direction: this.props.lang === "en" ? "ltr" : "rtl",
                  float: this.props.lang === "en" ? "left" : "right",
                  borderRadius: "16px",
                  boxShadow: "0px 4px 4px rgba(0,0,0,0.3)",
                }}
              >
                <div
                  className="product discount product_filter"
                  style={{
                    borderRadius: "16px",
                  }}
                >
                  <div
                    className="product_image"
                    onClick={() => jumpTo(`/single-product/${item.product.id}`)}
                  >
                    <img
                      src={`${BASE_URL_SHORT}/upload/${item.product.image}`}
                      alt=""
                      className="img-fluid"
                    />
                  </div>

                  <div className="product_info">
                    <h6 className="product_name" style={{ minHeight: "40px" }}>
                      <div>
                        {this.props.lang == "en"
                          ? item.product.en_name.length > 30
                            ? item.product.en_name.slice(0, 30) + "..."
                            : item.product.en_name
                          : item.product.ar_name.length > 30
                          ? item.product.ar_name.slice(0, 30) + "..."
                          : item.product.ar_name}
                      </div>
                    </h6>
                    <div
                      style={{
                        height: "2em",
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <div
                        style={{
                          background: item.color,
                          width: "40px",
                          borderRadius: "8px",
                          height: "75%",
                          border: "0.5px solid",
                          boxShadow: "0px 0px 2px 2px rgba(0,0,0,1)",
                        }}
                      ></div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 10,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          style={{
                            display: "inline-block",
                            fontSize: "13px",
                            fontWeight: 500,
                            lineHeight: "20px",
                          }}
                        >
                          {this.props.lang === "en" ? "Price: " : "السعر: "}
                        </span>
                        <span
                          style={{
                            display: "inline-block",
                            fontSize: "13px",
                            fontWeight: 500,
                            lineHeight: "20px",
                            color: "#fbba1b",
                          }}
                        >
                          {`${item.product.selling_price} ${
                            this.props.lang === "en" ? "Dinar" : "دينار"
                          }`}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          style={{
                            display: "inline-block",
                            fontSize: "13px",
                            fontWeight: 500,
                            lineHeight: "20px",
                          }}
                        >
                          {this.props.lang === "en" ? "Quantity: " : "الكمية: "}
                        </span>
                        <span
                          style={{
                            display: "inline-block",
                            fontSize: "13px",
                            fontWeight: 500,
                            lineHeight: "20px",
                            color: "#fbba1b",
                          }}
                        >
                          {item.count}
                        </span>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <span
                        style={{
                          display: "inline-block",
                          fontSize: "15px",
                          fontWeight: 500,
                          lineHeight: "30px",
                        }}
                      >
                        {this.props.lang === "en" ? "Total: " : "الإجمالي: "}
                      </span>
                      <span
                        style={{
                          display: "inline-block",
                          fontSize: "15px",
                          fontWeight: 500,
                          lineHeight: "30px",
                          color: "#fbba1b",
                        }}
                      >
                        {`${item.total} ${
                          this.props.lang === "en" ? "Dinar" : "دينار"
                        }`}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div class="container">
            <div class="row">
              <div class="col-12 col-md-6">
                {this.props.order.items.length > 0 && (
                  <InfoCard
                    title={this.props.lang === "en" ? "The Bill" : "الفاتورة"}
                    elements={[
                      {
                        title:
                          this.props.lang === "en"
                            ? "Total # of products"
                            : "عدد المنتجات الكلي",
                        value: this.props.order.items.length,
                      },
                      {
                        title:
                          this.props.lang === "en"
                            ? "Amount paid manually"
                            : "المبلغ المدفوع يدوياً",
                        value:
                          this.props.lang === "en"
                            ? `${this.props.order.payment_manually} Dinar`
                            : `${this.props.order.payment_manually} دينار`,
                      },
                      {
                        title:
                          this.props.lang === "en"
                            ? "Total amount"
                            : "المبلغ الكلي",
                        value: (
                          <span
                            style={{
                              display: "inline-block",
                              fontSize: "18px",
                              fontWeight: 500,
                              lineHeight: "30px",
                              color: "#fbba1b",
                            }}
                          >
                            {this.props.lang === "en"
                              ? `${this.props.order.total} Dinar`
                              : `${this.props.order.total} دينار`}
                          </span>
                        ),
                      },
                    ]}
                    lang={this.props.lang}
                  />
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default SingleOrder;
