import API from "../axios/API";
import Auth from "../modules/Auth";
import LoginService from "../axios/LoginService";


export const login = async (email, password) => {
  return LoginService.Login(email, password)
    .then(resp => {
      console.log(resp)
      return resp.data.data
    }).catch(error => {
      console.clear()
      console.error(error)
    })
};
export const register = async (name, email, password, v_pasword, address) => {
  return LoginService.Register(name, email, password, v_pasword, address)
    .then(resp => {
      return resp
    }).catch(error => {
      console.clear()
      console.error(error)
    })
};