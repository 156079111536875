import API from "../../axios/API";
import CategoryService from "../../axios/CategoryService";

export const getAllCategories = () => dispatch => {
  dispatch({
    type: GET_ALL_CATEGORIES_BEGIN
  });
  return CategoryService.GetCategories()
    .then(result => {
      dispatch({
        type: GET_ALL_CATEGORIES_SUCCESS,
        payload: result
      });
      return result;
    }).catch(error => {
      dispatch({
        type: GET_ALL_CATEGORIES_FAIL,
        payload: {
          error
        }
      });
      return error;
    })
};

export const getCATEGORY = id => dispatch => {
  dispatch({
    type: GET_CATEGORY_BEGIN
  });
  return API({
      method: "GET",
      url: `/CATEGORIES/${id}`
    })
    .then(res => {
      dispatch({
        type: GET_CATEGORY_SUCCESS,
        payload: res
      });
      return res;
    })
    .catch(error => {
      dispatch({
        type: GET_CATEGORY_FAIL,
        payload: {
          error
        }
      });
      return error;
    });
};

export const getCATEGORIESByCategory = c => dispatch => {
  dispatch({
    type: GET_CATEGORIES_BY_CATEGORY_BEGIN
  });
  return API({
      method: "GET",
      url: `/CATEGORIES?category=${c}`
    })
    .then(res => {
      dispatch({
        type: GET_CATEGORIES_BY_CATEGORY_SUCCESS,
        payload: res
      });
      return res;
    })
    .catch(error => {
      dispatch({
        type: GET_CATEGORIES_BY_CATEGORY_FAIL,
        payload: {
          error
        }
      });
      return error;
    });
};

export const search = text => dispatch => {
  dispatch({
    type: SEARCH_BEGIN
  });
  return API({
      method: "GET",
      url: `/search?query=${text}`
    })
    .then(res => {
      dispatch({
        type: SEARCH_SUCCESS,
        payload: res
      });
      return res;
    })
    .catch(error => {
      dispatch({
        type: SEARCH_FAIL,
        payload: {
          error
        }
      });
      return error;
    });
};

// export const applyFilters = filter_string => dispatch => {
//   dispatch({
//     type: APPLY_FILTERS_BEGIN
//   });
//   return API({
//       method: "GET",
//       url: `/CATEGORIES?${filter_string}`
//     })
//     .then(res => {
//       dispatch({
//         type: APPLY_FILTERS_SUCCESS,
//         payload: res
//       });
//       return res;
//     })
//     .catch(error => {
//       dispatch({
//         type: APPLY_FILTERS_FAIL,
//         payload: {
//           error
//         }
//       });
//       return error;
//     });
// };

// export const APPLY_FILTERS_BEGIN = "APPLY_FILTERS_BEGIN";
// export const APPLY_FILTERS_SUCCESS = "APPLY_FILTERS_SUCCESS";
// export const APPLY_FILTERS_FAIL = "APPLY_FILTERS_FAIL";

export const SEARCH_BEGIN = "SEARCH_BEGIN";
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
export const SEARCH_FAIL = "SEARCH_FAIL";

export const GET_ALL_CATEGORIES_BEGIN = "GET_ALL_CATEGORIES_BEGIN";
export const GET_ALL_CATEGORIES_SUCCESS = "GET_ALL_CATEGORIES_SUCCESS";
export const GET_ALL_CATEGORIES_FAIL = "GET_ALL_CATEGORIES_FAIL";

export const GET_CATEGORY_BEGIN = "GET_CATEGORY_BEGIN";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_FAIL = "GET_CATEGORY_FAIL";

export const GET_CATEGORIES_BY_CATEGORY_BEGIN = "GET_CATEGORIES_BY_CATEGORY_BEGIN";
export const GET_CATEGORIES_BY_CATEGORY_SUCCESS =
  "GET_CATEGORIES_BY_CATEGORY_SUCCESS";
export const GET_CATEGORIES_BY_CATEGORY_FAIL = "GET_CATEGORIES_BY_CATEGORY_FAIL";