import { connect } from "react-redux";
import { getMyOrders } from "../../redux/actions/OrdersAction";
import Orders from "./Orders";

const mapStoreToProps = (state) => {
  return {
    orders: state.orders.orders, // Use an empty object if orders is undefined
    lang: state.lang,
    currency: state.currency,
    pageIndex: state.orders.pageIndex, // Use default value if pageIndex is missing
    pageSize: state.orders.pageSize, // Use default value if pageLength is missing
  };
};
const mapDispatchToProps = (dispatch) => ({
  getMyOrders: (filter) => dispatch(getMyOrders(filter)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(Orders);
