import React, { Component, useState } from "react";
import LoginRegister from "../../components/LoginRegisterModal";
import Auth from "../../modules/Auth";
import { BASE_URL } from "../../base";
import ProductService from "../../axios/ProductService";
import { toast } from "react-toastify";
import jumpTo from "../../modules/Navigation";

class SingleProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      color: "",
      size: "",
      pic: "",
      selectedSize: "",
      id: "",
      quantity: 0,
      modalShow: false,
      login: true,
      fav: false,
      currency: this.props.currency.currency,
      product: null,
      bigImage:
        this.props.product &&
        this.props.product.images &&
        this.props.product.images.length > 0
          ? this.props.product.images[0]
          : null,
    };
    this.addToFav = this.addToFav.bind(this);
    this.handleImageClick = this.handleImageClick.bind(this);
  }

  componentDidMount() {
    if (
      !this.props.product ||
      this.props.location.pathname.split("/").slice(-1)[0] !==
        this.props.product.id.toString()
    ) {
      this.props.getProduct(
        this.props.location.pathname.split("/").slice(-1)[0]
      );
      this.props.getVariantsByProductId(
        this.props.location.pathname.split("/").slice(-1)[0]
      );
    } else {
      this.setState({
        bigImage: this.props.product.images[0],
        color: JSON.parse(this.props.product.color)[0],
        quantity: this.getQuantity(),
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.product !== this.props.product) {
      this.setState({
        bigImage:
          this.props.product.images.length > 0
            ? this.props.product.images[0]
            : null,
        color: JSON.parse(this.props.product.color)[0],
        // quantity: this.getQuantity(),
      });
    }
    if (prevState.color !== this.state.color) {
      this.getQuantity();
    }
  }
  getQuantity = () => {
    const cartItems = JSON.parse(window.localStorage.getItem("cart"));
    if (cartItems && cartItems.products) {
      const curProd = cartItems.products.filter(
        (el) =>
          el.id === this.props.product.id.toString() &&
          el.color === this.state.color
      )[0];
      if (curProd) {
        this.setState({ quantity: curProd.count });
      } else {
        this.setState({ quantity: 0 });
      }
    } else {
      this.setState({ quantity: 0 });
    }
  };
  showHideModal = () => {
    this.setState({ modalShow: false });
  };

  loginClicked = () => {
    this.setState({ modalShow: true, login: true });
  };
  registerClicked = () => {
    this.setState({ modalShow: true, login: false });
  };
  addToFav = (id) => {
    ProductService.addToFavorite(id)
      .then((resp) => {
        this.setState({ fav: true });
        toast.success(resp);
      })
      .catch((er) => console.error(er));
  };
  handleImageClick(image) {
    this.setState({ bigImage: image });
  }
  handleThumbnailClick = (item) => {
    this.setState({
      color: item.color,
      size: item.size,
      pic: item.imagePath,
      selectedSize: "",
      id: item._id,
      cartItem: null,
    });
  };

  onAddClicked = () => {
    if (
      Auth.getUserDetails() !== undefined &&
      Auth.getUserDetails() !== null &&
      Auth.getToken() !== undefined
    ) {
      this.setState({ quantity: this.state.quantity + 1 });
      this.props.postCart(
        this.state.id || this.props.location.pathname.split("/").slice(-1)[0],
        this.state.quantity + 1,
        this.state.color,
        this.props.product.selling_price,
        this.props.product.images[0].path,
        this.props.product.en_name,
        this.props.product.ar_name
      );
    } else {
      this.setState({ modalShow: true });
    }
  };
  onRemoveClicked = () => {
    if (
      Auth.getUserDetails() !== undefined &&
      Auth.getUserDetails() !== null &&
      Auth.getToken() !== undefined
    ) {
      if (this.state.quantity > 0) {
        this.setState({ quantity: this.state.quantity - 1 });
        if (this.state.quantity === 1) {
          this.props.removeProductFromCart(
            this.state.id ||
              this.props.location.pathname.split("/").slice(-1)[0],
            this.state.color
          );
        } else {
          this.props.postCart(
            this.state.id ||
              this.props.location.pathname.split("/").slice(-1)[0],
            this.state.quantity - 1,
            this.state.color,
            this.props.product.selling_price,
            this.props.product.images[0].path,
            this.props.product.en_name,
            this.props.product.ar_name
          );
        }
      }
    } else {
      this.setState({ modalShow: true });
    }
  };

  addToBag = () => {
    if (
      Auth.getUserDetails() !== undefined &&
      Auth.getUserDetails() !== null &&
      Auth.getToken() !== undefined
    ) {
      this.props.postCart(
        this.state.id || this.props.location.pathname.split("/").slice(-1)[0],
        this.state.quantity,
        this.state.color,
        this.props.product.selling_price,
        this.props.product.images[0].path,
        this.props.product.en_name
      );
    } else {
      this.setState({ modalShow: true });
    }
  };

  productInCart = () => {
    let available = false;
    return available;
  };

  render() {
    return (
      <div className="container single_product_container">
        {this.props.product &&
          this.props.product.id.toString() ===
            this.props.location.pathname.split("/").slice(-1)[0] && (
            <div>
              <div className="row">
                <div className="col">
                  <div
                    class="breadcrumbs d-flex flex-row align-items-center"
                    style={
                      this.props.lang.lang == "en"
                        ? { direction: "ltr" }
                        : { direction: "rtl" }
                    }
                  >
                    <ul>
                      <li
                        onClick={() => jumpTo("/")}
                        style={{ color: "gray", cursor: "pointer" }}
                      >
                        {/* <a> */}
                        {this.props.lang.lang == "en"
                          ? `Home`
                          : `الصفحة الرئيسية`}
                        {/* </a> */}
                      </li>
                      <li
                        className="active"
                        onClick={() => jumpTo("/categories")}
                        style={{ color: "gray", cursor: "pointer" }}
                      >
                        {/* <a href="/categories"> */}
                        <i
                          className={
                            this.props.lang.lang == "en"
                              ? `fa fa-angle-right`
                              : `fa fa-angle-left`
                          }
                          style={
                            this.props.lang == "en"
                              ? {}
                              : { marginLeft: "17px" }
                          }
                          aria-hidden="true"
                        ></i>
                        {this.props.lang.lang == "en" ? `Products` : `المنتجات`}
                        {/* </a> */}
                      </li>
                      <li
                        className="active"
                        onClick={() =>
                          jumpTo(
                            `/categories?category_id=${this.props.product.category.id}`
                          )
                        }
                        style={{
                          color: "gray",
                          cursor: "pointer",
                          textTransform: "capitalize",
                        }}
                      >
                        {/* <a href="/categories"> */}
                        <i
                          className={
                            this.props.lang.lang == "en"
                              ? `fa fa-angle-right`
                              : `fa fa-angle-left`
                          }
                          style={
                            this.props.lang == "en"
                              ? {}
                              : { marginLeft: "17px" }
                          }
                          aria-hidden="true"
                        ></i>
                        {this.props.lang.lang == "en"
                          ? this.props.product.category.name_en
                          : this.props.product.category.name_ar}
                        {/* </a> */}
                      </li>

                      <li className="active">
                        <i
                          className={
                            this.props.lang.lang == "en"
                              ? `fa fa-angle-right`
                              : `fa fa-angle-left`
                          }
                          style={
                            this.props.lang == "en"
                              ? {}
                              : { marginLeft: "17px" }
                          }
                          aria-hidden="true"
                        ></i>
                        {this.props.lang.lang === "en"
                          ? this.props.product.en_name
                          : this.props.product.ar_name}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-7">
                  <div className="single_product_pics">
                    <div className="row">
                      <div className="col-lg-3 image_col order-lg-2 order-1">
                        <div className="row">
                          {this.props.product.images.map((image, index) => (
                            <div className="col-12 mb-3" key={index}>
                              <img
                                src={`${process.env.REACT_APP_BASE_URL_SHORT}/upload/${image.path}`}
                                alt={`product-image-${index}`}
                                className={`w-100 h-75 ${
                                  image === this.state.bigImage
                                    ? "border border-cyan rounded shadow"
                                    : ""
                                }`}
                                style={{
                                  borderRadius: "5px",
                                  boxShadow: `${
                                    image === this.state.bigImage
                                      ? "0 0 10px rgba(0, 0, 0, 0.5)"
                                      : "none"
                                  }`,
                                  cursor: "pointer",
                                }}
                                onClick={() => this.handleImageClick(image)}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="col-lg-9 image_col order-lg-2 order-1">
                        <div className="single_product_image">
                          <div
                            className="single_product_image_background"
                            style={{
                              backgroundImage: this.state.bigImage?.path
                                ? `url(${process.env.REACT_APP_BASE_URL_SHORT}/upload/${this.state.bigImage.path})`
                                : "",
                              borderRadius: "20px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div
                    className="product_details"
                    style={{
                      direction: this.props.lang.lang == "en" ? "ltr" : "rtl",
                      float: this.props.lang.lang == "en" ? "left" : "right",
                      textAlign:
                        this.props.lang.lang == "en" ? "left" : "right",
                    }}
                  >
                    <div className="product_details_title">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "8px",
                        }}
                      >
                        <h2>
                          {this.props.lang.lang == "en"
                            ? this.props.product.en_name
                            : this.props.product.ar_name}
                        </h2>
                        <h2 style={{ color: "gray", textTransform: "none" }}>
                          /
                        </h2>
                        <h2 style={{ color: "#F0B21E" }}>
                          {this.props.product.brand}
                        </h2>
                      </div>
                      {(this.props.product.en_availability ||
                        this.props.product.ar_availability) && (
                        <div className="original_price">
                          <span>
                            {`${
                              this.props.lang.lang === "en"
                                ? this.props.product.en_availability
                                : this.props.product.ar_availability
                            }`}
                          </span>
                        </div>
                      )}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          textAlign:
                            this.props.lang.lang == "en" ? "left" : "right",
                        }}
                      >
                        <p
                          style={{
                            fontSize:
                              this.props.lang.lang == "en" ? "14px" : "16px",
                          }}
                        >
                          {this.props.lang.lang == "en"
                            ? this.props.product.en_description
                            : this.props.product.ar_description}
                        </p>
                      </div>
                    </div>
                    <div className="original_price">
                      <span>
                        {/* {this.props.currency.currency == "dollar"
                          ? `${this.props.product.selling_price} $`
                          : `${this.props.product.selling_price * 250.27} YRI`} */}
                        {`${this.props.product.selling_price} ${
                          this.props.lang.lang === "en" ? "Dinar" : "دينار"
                        }`}
                      </span>
                    </div>
                    <div className="product_color">
                      <h6>
                        {this.props.lang.lang == "en"
                          ? `Code: `
                          : "رمز المنتج: "}
                      </h6>

                      <span
                        style={{
                          color: "gray",
                          fontSize: 14,
                          fontWeight: 500,
                          paddingRight: "10px",
                          paddingLeft: "10px",
                        }}
                      >
                        {this.props.product.code}
                      </span>
                    </div>
                    <div className="product_color">
                      <h6>
                        {this.props.lang.lang == "en"
                          ? `Quantity: `
                          : "الكمية: "}
                      </h6>

                      <span
                        style={{
                          color: "gray",
                          fontSize: 14,
                          fontWeight: 500,
                          paddingRight: "10px",
                          paddingLeft: "10px",
                        }}
                      >
                        {this.props.product.entity}
                      </span>
                    </div>
                    <div className="product_color">
                      <h6>
                        {this.props.lang.lang == "en" ? `Colors ` : "الألوان "}
                      </h6>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "4px",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        {JSON.parse(this.props.product.color).map((e) => (
                          <div
                            style={{
                              background: `${e}`,
                              width: "40px",
                              borderRadius: "8px",
                              height: "16px",
                              cursor: "pointer",
                              border: "1px solid",
                              boxShadow:
                                this.state.color === `${e}`
                                  ? "0px 2px 6px 2px #00000080"
                                  : "none",
                            }}
                            onClick={() => this.setState({ color: `${e}` })}
                          ></div>
                        ))}
                      </div>
                    </div>
                    {(this.props.product.width ||
                      this.props.product.height ||
                      this.props.product.depth) && (
                      <div className="product_color">
                        <h6>
                          {this.props.lang.lang == "en"
                            ? `Dimensions `
                            : "الأبعاد "}
                        </h6>
                        <table style={{ width: "100%" }}>
                          <thead style={{ width: "100%" }}>
                            <tr style={{ width: "100%" }}>
                              <th
                                style={{
                                  width: "33%",
                                  textAlign: "center",
                                  fontSize: 13,
                                  fontWeigh: 500,
                                }}
                              >
                                {this.props.lang.lang === "en"
                                  ? "Width (cm)"
                                  : "عرض (سم)"}
                              </th>
                              <th
                                style={{
                                  width: "33%",
                                  textAlign: "center",
                                  fontSize: 13,
                                  fontWeigh: 500,
                                }}
                              >
                                {this.props.lang.lang === "en"
                                  ? "Height (cm)"
                                  : "ارتفاع (سم)"}
                              </th>
                              <th
                                style={{
                                  width: "33%",
                                  textAlign: "center",
                                  fontSize: 13,
                                  fontWeigh: 500,
                                }}
                              >
                                {this.props.lang.lang === "en"
                                  ? "Depth (cm)"
                                  : "عمق (سم)"}
                              </th>
                            </tr>
                          </thead>
                          <tbody style={{ width: "100%" }}>
                            <tr style={{ width: "100%" }}>
                              <td
                                style={{
                                  width: "33%",
                                  textAlign: "center",
                                  fontSize: 13,
                                  fontWeight: 500,
                                  color: "gray",
                                  paddingTop: "10px",
                                }}
                              >
                                {this.props.product.width
                                  ? this.props.product.width
                                  : ""}
                              </td>
                              <td
                                style={{
                                  width: "33%",
                                  textAlign: "center",
                                  fontSize: 13,
                                  fontWeight: 500,
                                  color: "gray",
                                  paddingTop: "10px",
                                }}
                              >
                                {this.props.product.height
                                  ? this.props.product.height
                                  : ""}
                              </td>
                              <td
                                style={{
                                  width: "33%",
                                  textAlign: "center",
                                  fontSize: 13,
                                  fontWeight: 500,
                                  color: "gray",
                                  paddingTop: "10px",
                                }}
                              >
                                {this.props.product.depth
                                  ? this.props.product.depth
                                  : ""}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}

                    <div className="free_delivery d-flex flex-row align-items-center justify-content-center">
                      <span>
                        <i className="fas fa-truck"></i>
                      </span>
                    </div>
                    <div
                      className="quantity d-flex flex-column flex-sm-row align-items-sm-center"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "29px",

                        // visibility:
                        //   this.state.quantity === 0 ? "hidden" : "visible",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "#f0b21e",
                          visibility:
                            this.state.quantity === 0 ? "hidden" : "visible",
                          float:
                            this.props.lang.lang === "en" ? "left" : "right",
                        }}
                      >
                        {this.props.lang.lang == "en"
                          ? ` Quantity:`
                          : `الكمية:`}
                      </span>
                      <div
                        className="quantity_selector"
                        style={{
                          visibility:
                            this.state.quantity === 0 ? "hidden" : "visible",
                          float:
                            this.props.lang.lang === "en" ? "left" : "right",
                        }}
                      >
                        <span
                          className={
                            this.state.quantity > 0 ? "minus" : "minus disabled"
                          }
                          onClick={() => this.onRemoveClicked()}
                        >
                          <i className="fa fa-minus" aria-hidden="true"></i>
                        </span>
                        <span id="quantity_value">{this.state.quantity}</span>
                        <span
                          className="plus"
                          onClick={() => this.onAddClicked()}
                        >
                          <i className="fa fa-plus" aria-hidden="true"></i>
                        </span>
                      </div>
                      <div
                        className="red_button product-add_to_cart_button"
                        onClick={() => this.onAddClicked()}
                        style={{
                          visibility:
                            this.state.quantity !== 0 ? "hidden" : "visible",
                          minWidth: "max-content",
                          width: "50%",
                          cursor: "pointer",
                          borderRadius: "16px",
                          position: "absolute",
                          left: this.props.lang.lang === "en" ? 0 : "auto",
                          right: this.props.lang.lang === "ar" ? 0 : "auto",
                        }}
                      >
                        <div style={{ color: "#ffffff" }}>
                          {this.props.lang.lang == "en"
                            ? `add to cart`
                            : `إضافة إلى السلة`}
                        </div>
                      </div>

                      <div
                        onClick={() => this.addToFav(this.props.product.id)}
                        className="product_favorite d-flex flex-column align-items-center justify-content-center"
                      >
                        <i
                          className="far fa-heart"
                          style={this.state.fav ? { color: "red" } : {}}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        <LoginRegister
          show={this.state.modalShow}
          login={this.state.login}
          registerClicked={() => this.registerClicked()}
          loginClicked={() => this.loginClicked()}
          onHide={() => this.showHideModal()}
        />
      </div>
    );
  }
}

export default SingleProduct;
