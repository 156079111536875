import React, { Component } from "react";

import Heading from "../../components/Heading";
import CartService from "../../axios/cartService";
import { toast } from "react-toastify";
import { BASE_URL_SHORT } from "../../base";
import jumpTo from "../../modules/Navigation";
import SingleOrder from "../../components/orders/single-order";

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: this.props.orders,
      pageIndex: this.props.pageIndex,
      pageSize: this.props.pageSize,
      showOrder: false,
      selectedOrder: null,
    };
  }

  toggleOrderModalState = (newOrder) => {
    this.setState({ showOrder: !this.state.showOrder });
    this.setState({ selectedOrder: newOrder });
  };

  componentDidMount() {
    this.props.getMyOrders({
      pageIndex: this.state.pageIndex,
      pageSize: this.state.pageSize,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.orders != this.props.orders)
      this.setState({
        orders: this.props.orders,
      });
    if (prevState.pageIndex !== this.state.pageIndex) {
      this.props.getMyOrders({
        pageIndex: this.state.pageIndex,
        pageSize: this.state.pageSize,
      });
    }
  }

  render() {
    return (
      <div className="shopping--cart" data-aos="fade-up">
        <div className="container">
          <div className="shopping--cart--container">
            <div className="row ">
              <Heading
                title={this.props.lang.lang == "en" ? "My Orders" : "طلباتي"}
                data-aos="fade-up"
              />
            </div>
            <div style={{ height: 30 }}></div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 12,
              }}
            >
              {this.state.orders &&
                this.state.orders.data &&
                this.state.orders.data.length > 0 &&
                this.state.orders.data.map((order) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 6,
                      borderRadius: "12px",
                      border: "1px solid",
                      borderColor: "#2B205E",
                      padding: "10px",
                      boxShadow: "1px 1px 2px 2px #2B205E",
                      direction: this.props.lang.lang === "en" ? "ltr" : "rtl",
                      float: this.props.lang.lang === "en" ? "left" : "right",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      {" "}
                      <span
                        style={{
                          border: "1px solid",
                          borderColor: order.order_status.color,
                          color: order.order_status.color,
                          padding: "8px",
                          borderRadius: "6px",
                          boxShadow: `0px 0px 1px 1px ${order.order_status.color}`,
                        }}
                      >
                        {this.props.lang.lang == "en"
                          ? order.order_status.en_name
                          : order.order_status.ar_name}
                      </span>
                      <span
                        style={{
                          display: "inline-block",
                          fontSize: "18px",
                          fontWeight: 500,
                          lineHeight: "30px",
                          color: "#fbba1b",
                        }}
                      >
                        {`${order.total} ${
                          this.props.lang.lang === "en" ? "Dinar" : "دينار"
                        }`}
                      </span>
                      <span
                        style={{
                          color: "gray",
                          fontWeight: 500,
                          cursor: "pointer",
                        }}
                        onClick={() => this.toggleOrderModalState(order)}
                      >
                        {this.props.lang.lang === "en"
                          ? "View details"
                          : "مشاهدة التفاصيل"}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 15,
                        flexWrap: "wrap",
                        padding: 15,
                      }}
                    >
                      {order.items.map((item, index) => (
                        <div
                          className="product-item men"
                          style={{
                            direction:
                              this.props.lang.lang === "en" ? "ltr" : "rtl",
                            float:
                              this.props.lang.lang === "en" ? "left" : "right",
                            borderRadius: "16px",
                            boxShadow: "0px 4px 4px rgba(0,0,0,0.3)",
                          }}
                        >
                          <div
                            className="product discount product_filter"
                            style={{
                              borderRadius: "16px",
                            }}
                          >
                            <div
                              className="product_image"
                              onClick={() =>
                                jumpTo(`/single-product/${item.product.id}`)
                              }
                            >
                              <img
                                src={`${BASE_URL_SHORT}/upload/${item.product.image}`}
                                alt=""
                                className="img-fluid"
                              />
                            </div>

                            <div className="product_info">
                              <h6
                                className="product_name"
                                style={{ minHeight: "40px" }}
                              >
                                <div>
                                  {this.props.lang.lang == "en"
                                    ? item.product.en_name.length > 30
                                      ? item.product.en_name.slice(0, 30) +
                                        "..."
                                      : item.product.en_name
                                    : item.product.ar_name.length > 30
                                    ? item.product.ar_name.slice(0, 30) + "..."
                                    : item.product.ar_name}
                                </div>
                              </h6>
                              <div
                                style={{
                                  height: "2em",
                                  display: "flex",
                                  width: "100%",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginBottom: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    background: item.color,
                                    width: "40px",
                                    borderRadius: "8px",
                                    height: "75%",
                                    border: "0.5px solid",
                                    boxShadow: "0px 0px 2px 2px rgba(0,0,0,1)",
                                  }}
                                ></div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: 10,
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "10px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <span
                                    style={{
                                      display: "inline-block",
                                      fontSize: "13px",
                                      fontWeight: 500,
                                      lineHeight: "20px",
                                    }}
                                  >
                                    {this.props.lang.lang === "en"
                                      ? "Price: "
                                      : "السعر: "}
                                  </span>
                                  <span
                                    style={{
                                      display: "inline-block",
                                      fontSize: "13px",
                                      fontWeight: 500,
                                      lineHeight: "20px",
                                      color: "#fbba1b",
                                    }}
                                  >
                                    {`${item.product.selling_price} ${
                                      this.props.lang.lang === "en"
                                        ? "Dinar"
                                        : "دينار"
                                    }`}
                                  </span>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "10px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <span
                                    style={{
                                      display: "inline-block",
                                      fontSize: "13px",
                                      fontWeight: 500,
                                      lineHeight: "20px",
                                    }}
                                  >
                                    {this.props.lang.lang === "en"
                                      ? "Quantity: "
                                      : "الكمية: "}
                                  </span>
                                  <span
                                    style={{
                                      display: "inline-block",
                                      fontSize: "13px",
                                      fontWeight: 500,
                                      lineHeight: "20px",
                                      color: "#fbba1b",
                                    }}
                                  >
                                    {item.count}
                                  </span>
                                </div>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "10px",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <span
                                  style={{
                                    display: "inline-block",
                                    fontSize: "15px",
                                    fontWeight: 500,
                                    lineHeight: "30px",
                                  }}
                                >
                                  {this.props.lang.lang === "en"
                                    ? "Total: "
                                    : "الإجمالي: "}
                                </span>
                                <span
                                  style={{
                                    display: "inline-block",
                                    fontSize: "15px",
                                    fontWeight: 500,
                                    lineHeight: "30px",
                                    color: "#fbba1b",
                                  }}
                                >
                                  {`${item.total} ${
                                    this.props.lang.lang === "en"
                                      ? "Dinar"
                                      : "دينار"
                                  }`}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              {this.state.orders &&
                this.state.orders.meta &&
                this.state.orders.data.length > 0 &&
                this.state.orders.meta.last_page !== 1 && (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      // gap: "45px",
                      direction: this.props.lang.lang == "en" ? "ltr" : "rtl",
                    }}
                  >
                    {/* Render the productsToDisplay here */}
                    <button
                      onClick={() =>
                        this.setState({
                          pageIndex: this.state.pageIndex - 1,
                        })
                      }
                      style={{
                        width: "80px",
                        height: "30px",
                        border: "none",
                        backgroundColor:
                          this.state.pageIndex === 1 ? "gray" : "#B18432",
                        // borderRadius: "4px",
                      }}
                      disabled={this.state.pageIndex === 1}
                    >
                      <p
                        style={{
                          color: "white",
                          fontSize: "12px",
                          padding: "4px",
                        }}
                      >
                        {this.props.lang.lang == "en" ? "Previous" : "السابق"}
                      </p>
                    </button>
                    <div>
                      {this.state.orders.meta.last_page > 6 ? (
                        <input
                          type="number"
                          value={this.state.pageIndex}
                          onChange={(e) =>
                            this.setState({
                              pageIndex: parseInt(e.target.value),
                            })
                          }
                          min="1"
                          max={this.state.orders.meta.last_page}
                          style={{
                            height: "30px",
                            width: "50px",
                            textAlign: "center",
                            fontSize: "13px",
                          }}
                        />
                      ) : (
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          {Array.from(
                            {
                              length: this.state.orders.meta.last_page,
                            },
                            (_, i) => (
                              <div
                                key={i}
                                onClick={() =>
                                  this.setState({ pageIndex: i + 1 })
                                }
                                style={{
                                  height: "30px",
                                  width: "30px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  border: "1px black solid",
                                  color:
                                    this.state.pageIndex === i + 1
                                      ? "#B18432"
                                      : "black",
                                  cursor: "pointer",
                                }}
                              >
                                {i + 1}
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </div>
                    <button
                      onClick={() =>
                        this.setState({
                          pageIndex: this.state.pageIndex + 1,
                        })
                      }
                      style={{
                        width: "80px",
                        height: "30px",
                        border: "none",
                        backgroundColor:
                          this.state.orders.meta &&
                          this.state.pageIndex ===
                            this.state.orders.meta.last_page
                            ? "gray"
                            : "#B18432",
                        // borderRadius: "4px",
                      }}
                      disabled={
                        this.state.orders.meta &&
                        this.state.pageIndex ===
                          this.state.orders.meta.last_page
                      }
                    >
                      <p
                        style={{
                          color: "white",
                          fontSize: "12px",
                          padding: "4px",
                        }}
                      >
                        {this.props.lang.lang == "en" ? "Next" : "التالي"}
                      </p>
                    </button>
                  </div>
                )}
            </div>
          </div>
        </div>
        {this.state.showOrder ? (
          <SingleOrder
            lang={this.props.lang.lang}
            show={this.state.showOrder}
            onHide={() => this.toggleOrderModalState()}
            order={this.state.selectedOrder}
          />
        ) : null}
      </div>
    );
  }
}

export default Orders;
