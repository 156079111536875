import React, { Component } from "react";
import SingleProduct from "../../components/Products/SingleProduct";
import Auth from "../../modules/Auth";
import LoginRegister from "../../components/LoginRegisterModal";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import jumpTo from "../../modules/Navigation";
import { debounce } from "lodash";
import Select from "react-dropdown-select";
import "./custom-select-style.css";
import ProductGridSkeleton from "./components/product-grid-skeleton";

class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: this.props.categories,
      products: this.props.products,
      filteredProducts: this.props.products,
      currency: props.currency.currency,
      modalShow: false,
      login: true,
      active:
        parseInt(
          new URLSearchParams(this.props.location.search).get("category_id")
        ) || this.props.active,
      pageIndex: this.props.pageIndex,
      pageLength: this.props.pageLength,
      search: this.props.search,
      lang: this.props.lang.lang,
      loading: this.props.productLoading,
      cart: this.props.cart,
    };
    this.addToBag = this.addToBag.bind(this);
  }
  componentDidMount() {
    if (!this.props.categories) {
      this.props.getAllCategories();
    }
    const catIdFromUrl = parseInt(
      new URLSearchParams(this.props.location.search).get("category_id")
    );
    if (!this.props.products || catIdFromUrl) {
      this.props.getAllProducts({
        pageIndex: this.state.pageIndex,
        pageLength: this.state.pageLength,
        category_id: catIdFromUrl
          ? catIdFromUrl
          : this.state.active > 0
          ? this.state.active
          : undefined,
      });
    }
  }
  handleInputChange(event) {
    if (event.target != null) {
      this.setState({ search: event.target.value });
    }
  }

  showHideModal = () => {
    this.setState({ modalShow: false });
  };

  loginClicked = () => {
    this.setState({ modalShow: true, login: true });
  };
  registerClicked = () => {
    this.setState({ modalShow: true, login: false });
  };

  addToBag = (id, count, color, price, image, name) => {
    if (
      Auth.getUserDetails() !== undefined &&
      Auth.getUserDetails() !== null &&
      Auth.getToken() !== undefined
    ) {
      if (color == "")
        this.props.lang.lang == "en"
          ? toast.error("you must select color")
          : toast.error("يجب تحديد لون المنتج الذي تريده");
      else {
        let cart = this.props.postCart(id, count, color, price, image, name);
        this.props.lang.lang == "en"
          ? toast.success("Product added to cart successfully")
          : toast.success("تم إضافة المنتج بنجاح إلى السلة");
      }
    } else {
      this.setState({ modalShow: true });
    }
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.products != this.props.products)
      this.setState({
        products: this.props.products,
        // filteredProducts: this.props.products,
      });
    if (prevProps.productLoading != this.props.productLoading)
      this.setState({
        loading: this.props.productLoading,
        // filteredProducts: this.props.products,
      });
    if (prevProps.cart != this.props.cart)
      this.setState({
        cart: this.props.cart,
        // filteredProducts: this.props.products,
      });
    if (prevState.pageIndex !== this.state.pageIndex) {
      this.props.getAllProducts({
        pageIndex: this.state.pageIndex,
        pageLength: this.state.pageLength,
        category_id: this.state.active > 0 ? this.state.active : undefined,
      });
    }
    if (prevState.active !== this.state.active) {
      this.setState({ pageIndex: 1, pageLength: this.state.pageLength });
      this.props.getAllProducts({
        pageIndex: 1,
        pageLength: this.state.pageLength,
        category_id: this.state.active > 0 ? this.state.active : undefined,
      });
    }
    if (prevState.search !== this.state.search) {
      this.setState({ pageIndex: 1, pageLength: this.state.pageLength });
      this.props.getAllProducts({
        pageIndex: 1,
        pageLength: this.state.pageLength,
        category_id: this.state.active > 0 ? this.state.active : undefined,
        search: this.state.search,
      });
    }
    if (prevProps.lang.lang !== this.props.lang.lang) {
    }
    // if (prevState.loading !== this.state.loading) {
    //   this.setState({ filteredProducts: [] });
    // }
  }
  isProductAddedToCart = (id) => {
    if (typeof window !== "undefined") {
      const items = this.state.cart;
      if (items && items.products) {
        const curProd = items.products.filter(
          (el) => el.id === id.toString()
        )[0];
        if (curProd) {
          return true;
        } else {
          return false;
        }
      }
    }
  };
  getProuctInitialColorFromCart = (id) => {
    if (this.isProductAddedToCart(id)) {
      const items = this.state.cart;
      const curProd = items.products.filter((el) => el.id === id.toString())[0];
      return curProd.color;
    } else {
      return "";
    }
  };
  getDefaultCategory = (id) => {
    const { categories } = this.props;
    if (
      categories &&
      Array.isArray(categories) &&
      categories.length > 0 &&
      id !== -1
    ) {
      const selected = categories.filter((el) => el.id === id);
      if (selected.length > 0) {
        return [selected[0]];
      } else {
        return [];
      }
    } else {
      return [];
    }
  };
  render() {
    const { categories, products, applyFilters } = this.props;
    console.log(this.state.cart);
    return (
      <div className="container product_section_container">
        <div className="row">
          <div className="col-12 product_section clearfix">
            <div
              class="breadcrumbs d-flex flex-row align-items-center"
              style={
                this.props.lang.lang == "en"
                  ? { direction: "ltr" }
                  : { direction: "rtl" }
              }
            >
              <ul>
                <li
                  onClick={() => jumpTo("/")}
                  style={{ color: "gray", cursor: "pointer" }}
                >
                  {this.props.lang.lang == "en" ? `Home` : `الصفحة الرئيسية`}
                </li>
                <li class="active" style={{ color: "gray", cursor: "pointer" }}>
                  <i
                    className={
                      this.props.lang.lang == "en"
                        ? `fa fa-angle-right`
                        : `fa fa-angle-left`
                    }
                    style={
                      this.props.lang == "en" ? {} : { marginLeft: "17px" }
                    }
                    aria-hidden="true"
                  ></i>
                  {this.props.lang.lang == "en" ? `Categories` : `الأصناف`}
                </li>
              </ul>
            </div>
            <div className="main_content">
              <div
                class="products_iso container"
                style={{
                  direction: this.props.lang.lang === "en" ? "ltr" : "rtl",
                  float: this.props.lang.lang === "en" ? "left" : "right",
                }}
              >
                <div class="row row-cols-1 row-cols-md-2">
                  <div class="col-md-6 col-12">
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "column",
                        gap: "5px",
                        textAlign: "start",
                      }}
                    >
                      <label
                        style={{
                          fontSize: "16px",
                          fontWeight: 500,
                          paddingLeft: "4px",
                        }}
                      >
                        {this.props.lang.lang === "en" ? "Search" : "ابحث"}
                      </label>
                      <input
                        onChange={(event) => {
                          event.persist();
                          debounce(() => this.handleInputChange(event), 1000)();
                        }}
                        className="form-control-helper"
                        placeholder={
                          this.props.lang.lang == "en"
                            ? "By name or item code"
                            : "حسب الاسم أو رمز المنتج"
                        }
                        style={{
                          direction:
                            this.props.lang.lang === "en" ? "ltr" : "rtl",
                          marginBottom: "14px",
                          // borderRadius: "8px",
                          width: "80%",
                          // boxShadow: " 0 0 3px 1px rgba(#ff0000, 0.5)",
                        }}
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "column",
                        gap: "5px",
                        textAlign: "start",
                      }}
                    >
                      <label
                        style={{
                          fontSize: "16px",
                          fontWeight: 500,
                          paddingLeft: "4px",
                        }}
                      >
                        {this.props.lang.lang === "en" ? "Category" : "الصنف"}
                      </label>
                      <Select
                        className="form-control-helper"
                        options={categories ?? []}
                        values={this.getDefaultCategory(this.state.active)}
                        onChange={(value) => {
                          if (value.length > 0) {
                            this.setState({ active: value[0].id });
                          } else {
                            this.setState({ active: -1 });
                          }
                        }}
                        labelField={
                          this.props.lang.lang === "en" ? "name" : "name_a"
                        }
                        valueField="id"
                        placeholder={
                          this.props.lang.lang === "en" ? "All" : "الكل"
                        }
                        searchable={true}
                        searchBy={
                          this.props.lang.lang === "en" ? "name" : "name_a"
                        }
                        color="#fbba1b"
                        direction={
                          this.props.lang.lang === "en" ? "ltr" : "rtl"
                        }
                        style={{
                          borderRadius: "8px",
                          border: "none",
                          width: "80%",
                        }}
                        separator
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="row row-cols-sm-2 row-cols-md-3 row-cols-lg-4"
                  style={{
                    direction: this.props.lang.lang == "en" ? "ltr" : "rtl",
                    marginTop: "20px",
                  }}
                >
                  {this.state.loading && <ProductGridSkeleton />}
                  {!this.state.loading &&
                    this.state.products &&
                    this.state.products.data.map((item, index) => {
                      return (
                        <div
                          className="col-lg-3 col-md-4 col-sm-6 col-xs-12"
                          key={index}
                          data-aos="zoom-in"
                        >
                          <SingleProduct
                            currency={this.props.currency.currency}
                            lang={this.props.lang.lang}
                            productItem={item}
                            addToBag={this.addToBag}
                            cart={this.state.cart}
                            isProductAddedToCart={this.isProductAddedToCart(
                              item.id
                            )}
                            initialColorFromCart={this.getProuctInitialColorFromCart(
                              item.id
                            )}
                            showModal={() => this.setState({ modalShow: true })}
                            postCart={this.props.postCart}
                            removeProductFromCart={
                              this.props.removeProductFromCart
                            }
                          />
                        </div>
                      );
                    })}
                </div>
                <div
                  class="row"
                  style={{
                    direction: this.props.lang.lang == "en" ? "ltr" : "rtl",
                  }}
                >
                  {this.state.products &&
                    this.state.products.data.length > 0 &&
                    this.state.products.last_page !== 1 && (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "20px",
                          marginTop: "40px",
                          direction:
                            this.props.lang.lang == "en" ? "ltr" : "rtl",
                        }}
                      >
                        {/* Render the productsToDisplay here */}
                        <button
                          onClick={() =>
                            this.setState({
                              pageIndex: this.state.pageIndex - 1,
                            })
                          }
                          style={{
                            width: "102px",
                            height: "26px",
                            borderRadius: "8px",
                            border: "none",
                            backgroundColor:
                              this.state.products.current_page === 1
                                ? "#12121280"
                                : "#FABA1C",
                            // borderRadius: "4px",
                          }}
                          disabled={this.state.products.current_page === 1}
                        >
                          <p
                            style={{
                              color: "white",
                              fontSize: "14px",
                              fontWeight: 600,
                              lineHeight: "24px",
                            }}
                          >
                            {this.props.lang.lang == "en"
                              ? "Previous"
                              : "السابق"}
                          </p>
                        </button>
                        <div>
                          {this.state.products.last_page > 6 ? (
                            <input
                              type="number"
                              value={this.state.products.current_page
                                .toString()
                                .padStart(2, "0")}
                              onChange={(e) => {
                                // Get the current value and ensure it's a number
                                let newValue =
                                  parseInt(e.target.value, 10) || ""; // Handle empty input as ""

                                // Validate the entered value against min and max
                                if (
                                  newValue < 1 ||
                                  newValue > this.state.products.last_page
                                ) {
                                  // Prevent invalid input from changing state
                                  return; // Exit the function if out of range
                                }

                                // Update state only with valid input
                                this.setState({ pageIndex: newValue });
                              }}
                              min="1"
                              max={this.state.products.last_page}
                              className="form-control-helper"
                              style={{
                                height: "30px",
                                width: "60px",
                                textAlign: "center",
                                fontSize: "14px",
                                fontWeight: 600,
                                lineHeight: "24px",
                                // borderRadius: "8px",
                              }}
                            />
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              {Array.from(
                                {
                                  length: this.state.products.last_page,
                                },
                                (_, i) => (
                                  <div
                                    key={i}
                                    onClick={() =>
                                      this.setState({ pageIndex: i + 1 })
                                    }
                                    style={{
                                      height: "30px",
                                      width: "30px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      // border: "1px black solid",
                                      color:
                                        this.state.products.current_page ===
                                        i + 1
                                          ? "#FABA1C"
                                          : "black",
                                      cursor: "pointer",
                                      fontSize: "15px",
                                      fontWeight: 600,
                                      lineHeight: "24px",
                                    }}
                                  >
                                    {(i + 1).toString().padStart(2, "0")}
                                  </div>
                                )
                              )}
                            </div>
                          )}
                        </div>
                        <button
                          onClick={() =>
                            this.setState({
                              pageIndex: this.state.pageIndex + 1,
                            })
                          }
                          style={{
                            width: "102px",
                            height: "26px",
                            borderRadius: "8px",
                            border: "none",
                            backgroundColor:
                              this.state.products &&
                              this.state.products.current_page ===
                                this.state.products.last_page
                                ? "#12121280"
                                : "#FABA1C",
                            // borderRadius: "4px",
                          }}
                          disabled={
                            this.state.products &&
                            this.state.products.current_page ===
                              this.state.products.last_page
                          }
                        >
                          <p
                            style={{
                              color: "white",
                              fontSize: "14px",
                              fontWeight: 600,
                              lineHeight: "24px",
                            }}
                          >
                            {this.props.lang.lang == "en" ? "Next" : "التالي"}
                          </p>
                        </button>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <LoginRegister
          show={this.state.modalShow}
          login={this.state.login}
          registerClicked={() => this.registerClicked()}
          loginClicked={() => this.loginClicked()}
          onHide={() => this.showHideModal()}
        />
      </div>
    );
  }
}

export default withRouter(Category);
