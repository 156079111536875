import React, { useEffect, useState } from "react";
import jumpTo from "../../modules/Navigation";
import { BASE_URL_SHORT } from "../../base";
import ColorSlider from "./color-slider";

function SingleProduct(props) {
  const { productItem } = props;
  const [color, setColor] = useState(props.initialColorFromCart);
  const [qty, setQty] = useState(0);

  const [isProductAdded, setIsproductAdded] = useState(
    props.isProductAddedToCart
  );
  useEffect(() => {
    const items = props.cart;
    if (items && items.products) {
      const curProd = items.products.filter(
        (el) => el.id === productItem.id.toString() && el.color === color
      )[0];
      if (curProd) {
        setQty(curProd.count);
        setIsproductAdded(true);
      } else {
        setQty(0);
        setIsproductAdded(false);
      }
    } else {
      setQty(0);
      setIsproductAdded(false);
    }
  }, [color, props.cart]);
  const addToCart = (id, price, image, name) => {
    props.addToBag(id, qty, color, price, image, name);
  };

  const onAddClicked = () => {
    let curColor = color;
    if (curColor === "") {
      curColor = JSON.parse(productItem.color)[0];
      setColor(curColor);
    }
    setQty(qty + 1);
    props.postCart(
      productItem.id.toString(),
      qty + 1,
      curColor,
      productItem.selling_price,
      productItem.images[0].path,
      productItem.en_name,
      productItem.ar_name
    );
    setIsproductAdded(true);
  };

  const onRemoveClicked = () => {
    if (qty > 0) {
      setQty(qty - 1);
      if (qty === 1) {
        props.removeProductFromCart(productItem.id.toString(), color);
        setIsproductAdded(false);
      } else {
        props.postCart(
          productItem.id.toString(),
          qty - 1,
          color,
          productItem.selling_price,
          productItem.images[0].path,
          productItem.en_name,
          productItem.ar_name
        );
      }
    }
  };

  let source = "";
  if (
    productItem.images &&
    productItem.images[0] &&
    productItem.images[0].path
  ) {
    source = productItem.images[0].path;
  } else {
    source = productItem.image;
  }

  return (
    <div
      className="product-item men"
      style={{
        direction: props.lang === "en" ? "ltr" : "rtl",
        float: props.lang === "en" ? "left" : "right",
        boxShadow: "0px 4px 4px rgba(0,0,0,0.3)",
      }}
    >
      <div className="product discount product_filter">
        <div
          className="product_image"
          onClick={() => jumpTo(`/single-product/${productItem.id}`)}
        >
          <img
            src={`${BASE_URL_SHORT}/upload/${source}`}
            alt=""
            className="img-fluid"
          />
        </div>
        <div className="favorite favorite_left">
          <i className="far fa-heart" />
        </div>

        <div className="product_info">
          <p className="product_name">
            <div>
              {props.lang == "en" ? productItem.en_name : productItem.ar_name}
            </div>
          </p>
          <div className="product_color">
            <ColorSlider
              colors={productItem.color}
              onColorClick={(color) => setColor(color)}
              selectedColor={color}
              lang={props.lang}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              height: "30px",
              marginTop: "6px",
            }}
          >
            <p className="product_price" style={{ fontSize: "14px" }}>
              {`${productItem.selling_price} ${
                props.lang === "en" ? "Dinar" : "دينار"
              }`}
            </p>
            {!isProductAdded && (
              <img
                src={"/add-to-cart.png"}
                alt="add to cart"
                style={{ height: "26px", width: "26px", cursor: "pointer" }}
                onClick={() => onAddClicked()}
              />
            )}
            {isProductAdded && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  gap: "8px",
                }}
              >
                <img
                  src={"/remove.png"}
                  alt="add"
                  style={{ height: "23px", width: "23px", cursor: "pointer" }}
                  onClick={() => onRemoveClicked()}
                />
                <span
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "27px",
                    color: "#303030",
                    width: "20px",
                  }}
                >
                  {qty.toString().padStart(2, "0")}
                </span>
                <img
                  src={"/add.png"}
                  alt="add to cart"
                  style={{ height: "23px", width: "23px", cursor: "pointer" }}
                  onClick={() => onAddClicked()}
                />
              </div>
            )}
          </div>
          {/* <div
            className="quantity d-flex flex-column flex-sm-row align-items-sm-center"
            style={{ visibility: isProductAdded ? "visible" : "hidden" }}
          >
            <div
              className="quantity_selector"
              style={{ width: "100%", borderRadius: "4px" }}
            >
              <span
                className={qty > 0 ? "minus" : "minus disabled"}
                onClick={() => onRemoveClicked()}
              >
                <i className="fa fa-minus" aria-hidden="true"></i>
              </span>
              <span id="quantity_value">{qty}</span>
              <span className="plus" onClick={() => onAddClicked()}>
                <i className="fa fa-plus" aria-hidden="true"></i>
              </span>
            </div>
          </div>
          <div
            className="red_button product-add_to_cart_button"
            onClick={() => onAddClicked()}
            style={{
              visibility: isProductAdded ? "hidden" : "visible",
              marginTop: "-40px",
              cursor: "pointer",
              borderRadius: "4px",
            }}
          >
            <div style={{ color: "#ffffff" }}>
              {props.lang == "en" ? `add to cart` : `إضافة إلى السلة`}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default SingleProduct;
