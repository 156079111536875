import React from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import "./custom-styles.css";
const ColorSlider = ({ colors, selectedColor, onColorClick, lang }) => {
  const [sliderRef] = useKeenSlider({
    mode: "snap",
    slides: {
      perView: "auto", // Adjust as needed
      spacing: 4,
    },
    rtl: lang !== "en",
  });

  return (
    <div ref={sliderRef} className="keen-slider">
      {JSON.parse(colors).map((color) => (
        <div
          key={`${color}_container`} // Add a unique key for each slide
          className="keen-slider__slide"
          style={{
            height: "24px",
            width: "48px",
            display: "flex",
            alignItems: "start",
            justifyContent: "start",
          }}
        >
          <div
            key={color} // Add a unique key for each slide
            className="product_color_item"
            style={{
              border: "1px solid",
              background: color,
              boxShadow:
                selectedColor === color ? "0px 2px 6px 0px #00000080" : "none",
            }}
            onClick={() => onColorClick(color)}
          />
        </div>
      ))}
    </div>
  );
};

export default ColorSlider;
