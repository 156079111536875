import React from "react";
import { BASE_URL_SHORT } from "../../base";
import { Button } from "react-bootstrap";

function CartItem({ items, handleClick, lang, currency, removeProduct }) {
  console.log(items);
  return (
    <div style={{ marginTop: 30 }}>
      {items !== undefined &&
        items !== null &&
        Object.keys(items).map((id) => (
          <div
            className="row shopping--cart--item"
            style={lang == "en" ? { direction: "ltr" } : { direction: "rtl" }}
            data-aos="fade-up"
          >
            <div className="col-sm-2">
              <div className="cart--item--img">
                <img
                  src={`${BASE_URL_SHORT}/upload/${items[id].image}`}
                  alt=""
                  style={{
                    width: "150px",
                    height: "150px",
                    display: "inline-block",
                    borderRadius: "16px",
                    // boxShadow: "0px 0px 2px rgba(0,0,0,0.5)",
                  }}
                />
              </div>
            </div>
            <div className="col-sm-5">
              <div
                style={
                  lang == "ar"
                    ? {
                        justifyContent: "start",
                        direction: "rtl",
                        display: "flex",
                        marginTop: 10,
                        marginBottom: 10,
                      }
                    : { direction: "ltr", marginTop: 10, marginBottom: 10 }
                }
                className="basket--item--title"
              >
                <span style={{ fontSize: "16px" }}>
                  {lang === "en" ? items[id].en_name : items[id].ar_name}
                </span>
              </div>
              <div
                className="basket--item--quantity"
                style={
                  lang == "ar"
                    ? {
                        justifyContent: "start",
                        direction: "rtl",
                        display: "flex",
                        marginTop: 10,
                        marginBottom: 10,
                      }
                    : { direction: "ltr", marginTop: 10, marginBottom: 10 }
                }
              >
                <span style={{ fontSize: "13px" }}>
                  {lang == "en" ? "  Quantity: " : " الكمية: "}
                </span>
                <span
                  style={{
                    fontWeight: "bold",
                    margin: "0 10px 0 10px",
                    fontSize: "13px",
                  }}
                >
                  {items[id].count}
                </span>
              </div>
              <div
                className="basket--item--quantity"
                style={
                  lang == "ar"
                    ? {
                        justifyContent: "start",
                        direction: "rtl",
                        display: "flex",
                        marginTop: 10,
                        marginBottom: 10,
                      }
                    : { direction: "ltr", marginTop: 10, marginBottom: 10 }
                }
              >
                <span style={{ fontSize: "13px" }}>
                  {lang == "en" ? " Color: " : " اللون: "}
                </span>
                <span
                  className="ml-2"
                  style={{
                    width: "40px",
                    height: "20px",
                    display: "inline-block",
                    borderRadius: "8px",
                    margin: "0 10px 0 10px",
                    backgroundColor: items[id].color,
                    border: "1px solid",
                  }}
                ></span>
              </div>
              <div
                className="basket--item--price"
                style={
                  lang == "ar"
                    ? {
                        justifyContent: "start",
                        direction: "rtl",
                        display: "flex",
                        marginTop: 10,
                        marginBottom: 10,
                      }
                    : { direction: "ltr", marginTop: 10, marginBottom: 10 }
                }
              >
                <span style={{ fontSize: "13px" }}>
                  {lang == "en" ? " Price: " : " السعر: "}
                </span>
                <span
                  style={{
                    margin: "0 10px 0 10px",
                    fontWeight: "bold",
                    fontSize: "13px",
                  }}
                >
                  {/* {currency == "dollar"
                    ? `$${items[id].price}`
                    : `${(items[id].price * 250.27).toFixed(2)} YRI`} */}
                  {`${items[id].price} ${lang === "en" ? "Dinar" : "دينار"}`}
                </span>
              </div>
            </div>
            <div className="col-sm-5">
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  width: "100%",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  onClick={() => removeProduct(items[id].id, items[id].color)}
                  color="red"
                >
                  {lang === "en" ? "Remove" : "حذف"}
                </Button>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}

export default CartItem;
