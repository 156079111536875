import {
  GET_ORDERS_BY_USERID_BEGIN,
  GET_ORDERS_BY_USERID_SUCCESS,
} from "../actions/OrdersAction";

const initialState = {
  orders: null,
  loading: false,
  error: null,
  pageSize: 2,
  pageIndex: 1,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDERS_BY_USERID_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ORDERS_BY_USERID_SUCCESS:
      return {
        ...state,
        orders: action.payload,
        loading: false,
        pageSize: action.payload.meta.per_page,
        pageIndex: action.payload.meta.current_page,
      };

    default:
      return state;
  }
};
