import {
  GET_ALL_CATEGORIES_BEGIN,
  GET_ALL_CATEGORIES_SUCCESS,
  GET_ALL_CATEGORIES_FAIL,
  GET_CATEGORY_BEGIN,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FAIL,
  SEARCH_BEGIN,
  SEARCH_SUCCESS,
  SEARCH_FAIL,
} from "../actions/categoryAction";

const initialState = {
  categories: null,
  category: null,
  loading: false,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CATEGORIES_BEGIN:
      return {
        ...state,
        loading: true,
          error: null
      };
    case GET_ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
          categories: action.payload
      };
    case GET_ALL_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
          // error: action.payload.error.response.data
      };
    case GET_CATEGORY_BEGIN:
      return {
        ...state,
        loading: true,
          error: null
      };
    case GET_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
          category: action.payload
      };
    case GET_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
          // error: action.payload.error.response.data
      };
    case SEARCH_BEGIN:
      return {
        ...state,
        loading: true,
          error: null
      };
    case SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
          categories: action.payload
      };
    case SEARCH_FAIL:
      return {
        ...state,
        loading: false,
          // error: action.payload.error.response.data
      };
    default:
      return state;
  }
};