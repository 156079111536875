import api from './API';

export default class CategoryService {
    static async GetCategories() {
        return api.get('/category').then(resp => {
            if (resp.data.success)
                return resp.data.data
        }).catch((error) => {
            console.log("Api Error:", error);
            throw error;
        });
    }
}