

import API from "../../axios/API";
import ProductService from "../../axios/ProductService";

export const getVariantsByProductId = productId => dispatch => {
  dispatch({
    type: GET_VARIANTS_QUERY_BEGIN
  });
  return ProductService.GetProduct(productId)
    .then(result => {
      dispatch({
        type: GET_VARIANTS_QUERY_SUCCESS,
        payload: result
      });
      return result;
    }).catch(error => {
      dispatch({
        type: GET_VARIANTS_QUERY_FAIL,
        payload: {
          error
        }
      });
      return error;
    });
};

export const GET_VARIANTS_QUERY_BEGIN = "GET_VARIANTS_QUERY_BEGIN";
export const GET_VARIANTS_QUERY_SUCCESS = "GET_VARIANTS_QUERY_SUCCESS";
export const GET_VARIANTS_QUERY_FAIL = "GET_VARIANTS_QUERY_FAIL";
