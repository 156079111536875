import api from "./API";
import instance from "./API";
import axios from "axios";
import https from "https";
import Auth from "../modules/Auth";
export default class LoginService {
  instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    httpsAgent: new https.Agent({
      rejectUnauthorized: false,
    }),
    headers: {
      "Content-Type":
        "multipart/form-data; boundary=<calculated when request is sent>",
    },
  });
  static async Login(_email, _password) {
    const payload = {
      email: _email,
      password: _password,
      fcm_token: "afasdasd",
    };
    return instance
      .post("/login", payload)
      .then((resp) => {
        if (resp.data.success) {
          const new_user = {
            token: resp.data.data.token,
            user: resp.data.data.user,
          };
          Auth.setUserToken(new_user);
          return resp.data.data;
        }
      })
      .catch((error) => {
        console.log("Api Error:", error);
        throw error;
      });
  }
  static async Register(name, email, password, v_pasword, address) {
    const payload = {
      name: name,
      email: email,
      password: password,
      c_password: v_pasword,
      address: address,
      fcm_token: "frfr",
    };
    return instance
      .post("/register", payload)
      .then((resp) => {
        console.log("serv", resp.data);
        if (resp.data.success) {
          return resp.data;
        }
      })
      .catch((error) => {
        console.log("Api Error:", error);
        throw error;
      });
  }
  static async LoginWithGoogle(_email, _password) {
    const payload = {
      email: _email,
      password: _password,
      fcm_token: "afasdasd",
    };
    return api
      .post("/auth/google", payload)
      .then((resp) => {
        if (resp.data.success) return resp.data.data;
      })
      .catch((error) => {
        console.log("Api Error:", error);
        throw error;
      });
  }
  static async LoginWithFacebook(_email, _password) {
    const payload = {
      email: _email,
      password: _password,
      fcm_token: "afasdasd",
    };
    return api
      .post("/auth/facebook", payload)
      .then((resp) => {
        if (resp.data.success) return resp.data.data;
      })
      .catch((error) => {
        console.log("Api Error:", error);
        throw error;
      });
  }
}
