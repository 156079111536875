import API from "../../axios/API";
import Auth from "../../modules/Auth";

export const setLang = (lang) => (dispatch) => {
  dispatch({
    type: SET_LANG,
    payload: lang,
  });
  localStorage.setItem("lang", lang);
};
export const getLang = () => (dispatch) => {
  dispatch({
    type: GET_LANG,
  });
  return localStorage.getItem("lang");
};
export const SET_LANG = "SET_LANG";
export const GET_LANG = "GET_LANG";
