import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import "./style.css";
import Auth from "../../modules/Auth";
import EmptyCart from "../../assets/images/emptyCart.png";
import jumpTo from "../../modules/Navigation";
import { connect } from "react-redux";
import { BASE_URL_SHORT } from "../../base";
import { removeProductFromCart } from "../../redux/actions/cartAction";

class HomeCartView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cart: {},
    };
  }
  goToChechout = () => {
    jumpTo("/cart");
  };
  componentDidMount() {
    if (typeof window !== "undefined") {
      this.setState({ cart: JSON.parse(window.localStorage.getItem("cart")) });
    }
  }
  render() {
    const products = this.props.cart.cart.products;
    const total = this.props.cart.cart.total;
    const totalQty = this.props.cart.cart.totalQty;
    return (
      <Modal {...this.props} className={"right"}>
        <Modal.Header
          style={{
            direction: this.props.lang == "en" ? "ltr" : "rtl",
            textAlign: this.props.lang == "en" ? "left" : "right",
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Modal.Title
            style={{
              fontSize: this.props.lang == "en" ? "24px" : "20px",
              minWidth: "fit-content",
            }}
          >
            {this.props.lang == "en" ? `Your Cart` : `سلة المنتجات`}
          </Modal.Title>
          {products !== undefined &&
            products !== null &&
            Object.keys(products).length > 0 && (
              <span
                className="checkout--btn"
                onClick={() => this.goToChechout()}
              >
                {this.props.lang == "en" ? `checkout` : `تأكيد الطلب`}
              </span>
            )}
        </Modal.Header>
        <Modal.Body
          className="modal-body-content"
          style={{
            direction: this.props.lang == "en" ? "ltr" : "rtl",
            textAlign: this.props.lang == "en" ? "left" : "right",
          }}
        >
          <div>
            {products !== undefined &&
            products !== null &&
            Object.keys(products).length > 0 ? null : (
              <div className="empty--basket">
                <img src={EmptyCart} className="img-fluid" />
                <h4 style={{ textAlign: "center" }}>
                  {this.props.lang == "en" ? `Empty cart` : `السلة فارغة`}
                </h4>
              </div>
            )}
          </div>

          {products !== undefined &&
            products !== null &&
            Object.keys(products).map((id) => {
              return (
                <div key={id} className="basket--item">
                  <div className="basket--item--img">
                    <img
                      src={`${BASE_URL_SHORT}/upload/${products[id].image}`}
                      alt=""
                    />
                  </div>
                  <div className="basket--item--details">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "8px",
                        alignItems: "center",
                      }}
                    >
                      <div className="basket--item--title">
                        {this.props.lang == "en"
                          ? products[id].en_name
                          : products[id].ar_name}
                      </div>
                      <div
                        style={{
                          background: products[id].color,
                          width: "40px",
                          borderRadius: "8px",
                          height: "15px",
                          border: "1px solid",
                        }}
                      ></div>
                    </div>
                    <div className="basket--item--quantity">
                      <span style={{ fontSize: "13px" }}>
                        {this.props.lang == "en" ? `Quantity: ` : " الكمية: "}
                      </span>
                      <span style={{ fontSize: "13px" }}>
                        {products[id].count}
                      </span>
                    </div>
                    <div className="basket--item--price">
                      <span style={{ fontSize: "13px" }}>
                        {this.props.lang == "en" ? `Price: ` : " السعر: "}
                      </span>
                      <span style={{ fontSize: "13px" }}>{`${
                        products[id].price
                      } ${this.props.lang === "en" ? "Dinar" : "دينار"}`}</span>
                    </div>
                    <div className="basket--item--price">
                      <span style={{ fontSize: "13px" }}>
                        {this.props.lang == "en"
                          ? ` SubTotal: `
                          : " المجموع الجزئي: "}
                      </span>
                      <span style={{ fontSize: "13px" }}>
                        {/* {this.props.currency == "dollar"
                          ? `$${products[id].subTotal}`
                          : `${(products[id].subTotal * 235.17).toFixed(
                              2
                            )} YRI`} */}
                        {`${products[id].subTotal} ${
                          this.props.lang === "en" ? "Dinar" : "دينار"
                        }`}
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      onClick={() =>
                        this.props.removeProductFromCart(
                          products[id].id,
                          products[id].color
                        )
                      }
                      style={{
                        borderRadius: "50%",
                        backgroundColor: "red",
                        borderColor: "red",
                      }}
                      size="sm"
                    >
                      X
                    </Button>
                  </div>
                </div>
              );
            })}
          {products !== undefined &&
            products !== null &&
            Object.keys(products).length > 0 && (
              <div className="total--price-container">
                <h3 style={{ textAlign: "center" }}>
                  <span>
                    <span>
                      {this.props.lang == "en" ? ` Total: ` : " المجموع: "}
                    </span>
                  </span>
                  <span style={{ color: "#FE4C50" }}>
                    {/* {this.props.currency == "dollar"
                      ? `$${total}`
                      : `${(total * 235.17).toFixed(2)} YRI`} */}
                    {`${total} ${this.props.lang === "en" ? "Dinar" : "دينار"}`}
                  </span>
                </h3>
                <button
                  className="btn btn-wide log-btn"
                  style={{ marginTop: 20 }}
                  onClick={() => this.goToChechout()}
                >
                  <span>
                    {this.props.lang == "en" ? `Checkout ` : " تأكيد "}
                  </span>
                </button>
              </div>
            )}
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStoreToProps = (state) => ({
  cart: state.cart,
});
const mapDispatchToProps = (dispatch) => ({
  // getAllProducts: () => dispatch(getAllProducts()),
  // applyFilters: filter_string => dispatch(applyFilters(filter_string)),
  // postCart: (productId,count,color, price, image) => dispatch(postCart(productId, count, color, price, image))
  removeProductFromCart: (productId, _color) =>
    dispatch(removeProductFromCart(productId, _color)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(HomeCartView);
