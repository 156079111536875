import api from "./API";

export default class CartService {
  static async CheckOut(payload) {
    return api
      .post("/orders/check-out", payload)
      .then((resp) => {
        if (resp.data.success) {
          localStorage.removeItem("cart");
          return resp.data.data;
        }
      })
      .catch((error) => {
        console.log("Api Error:", error);
        throw error;
      });
  }

  static async GuestCheckOut(payload) {
    return api
      .post("/orders/guest-check-out", payload)
      .then((resp) => {
        if (resp.data.success) {
          localStorage.removeItem("cart");
          return resp.data.data;
        }
      })
      .catch((error) => {
        console.log(error.message);
        throw error;
      });
  }
}
