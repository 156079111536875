import { connect } from "react-redux";
import NavBar from "./NavBar";
// import { getDepartments } from "../../redux/actions/DepartmentAction";
import // getCartByUserId
"../../redux/actions/cartAction";
import { getLang, setLang } from "../../redux/actions/langAction";
import { setCurrency, getCurrency } from "../../redux/actions/CurrencyAction";
const mapStoreToProps = (state) => ({
  // departments: state.department.departments,
  cart: state.cart,
  lang: state.lang,
  currency: state.currency,
});

const mapDispatchToProps = (dispatch) => ({
  // getDepartments: dispatch(getDepartments()),
  // getCartByUserId: () => dispatch(getCartByUserId()),
  setLang: (lang) => dispatch(setLang(lang)),
  getLang: dispatch(getLang),
  setCurrency: (curr) => dispatch(setCurrency(curr)),
  getCurrency: dispatch(getCurrency),
});

export default connect(mapStoreToProps, mapDispatchToProps)(NavBar);
