import React, { Component } from "react";
import { Link } from "react-router-dom";
import HomeCartView from "../HomeCartView";
import MobileMenu from "../MobileMenu";
import device, { size } from "../../modules/mediaQuery";
import MediaQuery from "react-responsive";
import Auth from "../../modules/Auth";
import LoginRegister from "../LoginRegisterModal";
import logo from "../../assets/images/logo_bg.png";
import jumpTo from "../../modules/Navigation";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      loginShow: false,
      activeclass: false,
      lang: this.props.lang.lang,
      currency: this.props.currency.currency,
      activeLink: "",
    };
  }
  // componentDidMount() {
  //   if (Object.keys(this.props.cart).length < 1) {
  //     this.props.getCartByUserId();
  //   }
  // }

  showHideModal = () => {
    this.setState({ modalShow: !this.state.modalShow });
  };
  showLoginHideModal = () => {
    this.setState({ loginShow: !this.state.loginShow });
  };
  handleMenuClicked = () => {
    this.setState({ activeclass: !this.state.activeclass });
  };
  logout = (e) => {
    e.preventDefault();
    Auth.logout();
    window.location.href = "/";
  };
  loginClicked = () => {
    this.setState({ loginShow: true, login: true });
  };
  registerClicked = () => {
    this.setState({ loginShow: true, login: false });
  };
  setLanguage = (payload) => {
    this.setState({ lang: payload });
    this.props.setLang(payload);
  };
  setCurrency = (payload) => {
    this.setState({ currency: payload });
    this.props.setCurrency(payload);
  };
  componentDidMount() {
    const href = window.location.href;
    if (href.includes("categories")) {
      this.setState({ activeLink: "categories" });
    } else {
      this.setState({ activeLink: "home" });
    }
  }
  render() {
    const cart = this.props.cart;
    return (
      <div className="main_nav_container">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-right">
              <div className="logo_container">
                <Link to="/">
                  <img src={logo} className="logo-img" />
                </Link>
              </div>
              <nav className="navbar">
                <ul className="navbar_menu">
                  <li
                    onClick={() => {
                      jumpTo("/");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <a
                      className={
                        this.state.activeLink === "home" ? "active" : ""
                      }
                    >
                      {this.state.lang == "en" ? "home" : "الصفحة الرئيسية"}
                    </a>
                  </li>
                  <li
                    onClick={() => jumpTo("/categories")}
                    style={{ cursor: "pointer" }}
                  >
                    <a
                      className={
                        this.state.activeLink === "categories" ? "active" : ""
                      }
                    >
                      {this.state.lang == "en" ? "products" : "المنتجات"}
                    </a>
                  </li>
                  {/* <li
                    onClick={() => jumpTo("/categories")}
                    style={{ cursor: "pointer" }}
                  >
                    <a>{this.state.lang == "en" ? "categories" : "الأصناف"}</a>
                  </li> */}
                  <li className="mega-drop-down" style={{ cursor: "pointer" }}>
                    <a
                      style={{
                        display: "flex",
                        gap: "6px",
                        alignItems: "center",
                      }}
                    >
                      {this.state.lang == "en" ? "Language" : "اللغة"}
                      <i className="fa fa-angle-down" />
                    </a>
                    <div className="mega-menu">
                      <div className="mega-menu-wrap">
                        <div
                          className="mega-menu-content"
                          style={{
                            direction: this.state.lang == "en" ? "ltr" : "rtl",
                            float: this.state.lang == "en" ? "left" : "right",
                          }}
                        >
                          <ul
                            className="stander"
                            style={{
                              textAlign:
                                this.state.lang === "en" ? "left" : "right",
                            }}
                          >
                            <li key="english">
                              <a
                                onClick={this.setLanguage.bind(this, "en")}
                                className={
                                  this.state.lang == "en" ? "active" : ""
                                }
                              >
                                English
                              </a>
                            </li>
                            <li key="arabic">
                              <a
                                onClick={this.setLanguage.bind(this, "ar")}
                                className={
                                  this.state.lang == "ar" ? "active" : ""
                                }
                              >
                                العربية
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="mega-drop-down" style={{ cursor: "pointer" }}>
                    <a
                      style={{
                        display: "flex",
                        gap: "6px",
                        alignItems: "center",
                      }}
                    >
                      {this.state.lang == "en" ? "Currency" : "العملة"}

                      <i className="fa fa-angle-down" />
                    </a>
                    <div className="mega-menu">
                      <div className="mega-menu-wrap">
                        <div className="mega-menu-content">
                          <ul className="stander">
                            <li key="pounds">
                              <a
                                onClick={this.setCurrency.bind(this, "dinar")}
                                className={
                                  this.state.currency == "dinar" ? "active" : ""
                                }
                              >
                                {this.state.lang === "en" ? "Dinar" : "الدينار"}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div
                      style={{
                        height: "32px",
                        width: "1px",
                        border: "1px solid gray",
                        marginTop: "-10px",
                        marginBottom: "-10px",
                        marginRight: "15px",
                      }}
                    />
                  </li>
                  <li
                    className="checkout"
                    onClick={() => this.showHideModal()}
                    style={{ cursor: "pointer" }}
                  >
                    {/* <a
                      onClick={() => this.showHideModal()}
                      style={{ cursor: "pointer" }}
                    > */}
                    {/* <i className="fas fa-shopping-bag" /> */}
                    <img
                      src="/Vector.png"
                      alt="cart icon"
                      width="18px"
                      height="20px"
                      style={{ marginBottom: "4px" }}
                    />
                    {cart.totalQty !== undefined && (
                      <span id="checkout_items" className="checkout_items">
                        {cart.totalQty} sd;cmdm l,ald;aw ,;law al;w l;
                      </span>
                    )}
                    {/* </a> */}
                  </li>
                  {Auth.getUserDetails() !== undefined &&
                    Auth.getUserDetails() !== null &&
                    Auth.getToken() !== undefined && (
                      <li
                        onClick={() => jumpTo("/orders")}
                        style={{ cursor: "pointer" }}
                      >
                        <a>{this.state.lang == "en" ? "Orders" : "طلباتي"}</a>
                      </li>
                    )}
                  {Auth.getUserDetails() !== undefined &&
                    Auth.getUserDetails() !== null &&
                    Auth.getToken() !== undefined && (
                      <li
                        className="mega-drop-down"
                        style={{ cursor: "pointer" }}
                      >
                        <a>
                          {this.state.lang == "en"
                            ? `Welcome ${Auth.getUserDetails().name}`
                            : `مرحباً ${Auth.getUserDetails().name}`}
                        </a>
                        <div
                          className="mega-menu"
                          style={{
                            direction: this.state.lang == "en" ? "ltr" : "rtl",
                            float: this.state.lang == "en" ? "left" : "right",
                          }}
                        >
                          <div className="mega-menu-wrap">
                            <div
                              className="mega-menu-content"
                              style={{
                                textAlign:
                                  this.state.lang == "en" ? "left" : "right",
                              }}
                            >
                              <ul className="stander">
                                <li
                                  style={{
                                    width: "100%",
                                  }}
                                >
                                  <a href="#" onClick={(e) => this.logout(e)}>
                                    <i
                                      className="fas fa-sign-in-alt mx-1"
                                      aria-hidden="true"
                                    />
                                    {this.state.lang == "en"
                                      ? "Logout"
                                      : "تسجيل الخروج"}
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </li>
                    )}
                  {(Auth.getUserDetails() === undefined ||
                    Auth.getUserDetails() === null ||
                    Auth.getToken() === undefined) && (
                    <li>
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => this.loginClicked()}
                      >
                        {this.state.lang == "en" ? "Login" : "تسجيل الدخول"}
                      </a>
                    </li>
                  )}
                </ul>
                {/* <ul className="navbar_user"></ul> */}
                <div
                  className="hamburger_container"
                  onClick={() => this.handleMenuClicked()}
                >
                  <i className="fa fa-bars" />
                </div>
              </nav>
            </div>
          </div>
        </div>
        <MediaQuery query={device.max.tabletL}>
          <MobileMenu
            loginClicked={this.loginClicked}
            logout={this.logout}
            setCurrency={this.setCurrency}
            setLanguage={this.setLanguage}
            lang={this.props.lang}
            currency={this.props.currency}
            activeClass={this.state.activeclass}
            onClose={() => this.handleMenuClicked()}
          />
        </MediaQuery>
        {this.state.loginShow ? (
          <LoginRegister
            lang={this.props.lang.lang}
            show={this.state.loginShow}
            login={this.state.login}
            registerClicked={() => this.registerClicked()}
            loginClicked={() => this.loginClicked()}
            onHide={() => this.showLoginHideModal()}
          />
        ) : null}
        {this.state.modalShow ? (
          <HomeCartView
            currency={this.props.currency.currency}
            lang={this.props.lang.lang}
            cart={cart}
            show={this.state.modalShow}
            onHide={() => this.showHideModal()}
          />
        ) : null}
      </div>
    );
  }
}

export default NavBar;
