import api from "./API";

export default class ProductService {
  static async GetNewArriveProducts() {
    return api
      .get("/newarrive")
      .then((resp) => {
        if (resp.data.success) return resp.data.data;
      })
      .catch((error) => {
        console.log("Api Error:", error);
        throw error;
      });
  }
  static async GetProduct(id) {
    return api
      .get("/products/" + id)
      .then((resp) => {
        if (resp.data.success) return resp.data.data;
      })
      .catch((error) => {
        console.log("Api Error:", error);
        throw error;
      });
  }
  static async addToFavorite(id) {
    return api
      .post("/favorite/" + id)
      .then((resp) => {
        if (resp.data.success) return resp.data.data;
      })
      .catch((error) => {
        console.log("Api Error:", error);
        throw error;
      });
  }
  static async GetAllProducts(filter) {
    console.log(filter);
    return api
      .get("/products", { params: filter })
      .then((resp) => {
        if (resp) return resp;
      })
      .catch((error) => {
        console.log("Api Error:", error);
        throw error;
      });
  }
}
