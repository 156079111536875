import { connect } from "react-redux";
import Cart from "./Cart";
import {
  // getCartByUserId,
  postCart,
  clearCart,
  removeProductFromCart,
} from "../../redux/actions/cartAction";

const mapStoreToProps = (state) => ({
  cart: state.cart,
  lang: state.lang,
  currency: state.currency,
});
const mapDispatchToProps = (dispatch) => ({
  // getCartByUserId: dispatch(getCartByUserId()),
  postCart: (productId, count, _color, price, image, en_name, ar_name) =>
    dispatch(
      postCart(productId, count, _color, price, image, en_name, ar_name)
    ),
  clearCart: () => dispatch(clearCart()),
  removeProductFromCart: (productId, _color) =>
    dispatch(removeProductFromCart(productId, _color)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(Cart);
