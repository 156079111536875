import React, { useEffect, useState } from "react";
import CategoryService from "../../axios/CategoryService";
import { BASE_URL, BASE_URL_SHORT } from "../../base";
import Heading from "../Heading";
import { Link } from "react-router-dom/cjs/react-router-dom";

function CategoryBanner(props) {
  const [categories, setCategories] = useState([]);
  const [searchedCats, setSearchedCats] = useState([]);
  useEffect(() => {
    CategoryService.GetCategories().then((result) => {
      setCategories(result);
      setSearchedCats(result);
    });
  }, []);
  const searchCats = (event) => {
    let _val = event.target.value;
    let _cats = [];
    if (!!_val) {
      if (props.lang == "en") {
        _cats = categories?.filter((e) =>
          e.name.toLowerCase().includes(_val.toLowerCase())
        );
        setSearchedCats(_cats);
      } else {
        _cats = categories?.filter((e) =>
          e.name_a.toLowerCase().includes(_val.toLowerCase())
        );
        setSearchedCats(_cats);
      }
    } else setSearchedCats(categories);
  };
  return (
    <div
      className="banner"
      style={{ direction: props.lang === "en" ? "ltr" : "rtl" }}
    >
      <div className="container">
        <div className="row my-5">
          <Heading
            title={props.lang == "en" ? "Categories" : "الأصناف"}
            data-aos="fade-up"
          />
        </div>
        <div className="row my-3">
          <input
            onChange={searchCats}
            className="form-control"
            placeholder={
              props.lang == "en" ? "Search Categories...." : "بحث عن صنف..."
            }
            style={
              props.lang == "en"
                ? { direction: "ltr", backgroundColor: "beige" }
                : { direction: "rtl", backgroundColor: "beige" }
            }
          />
        </div>
        <div className="row">
          {searchedCats.map((cat, index) => {
            return (
              <div key={index} className="col-md-4">
                <Link to={`/categories?category_id=${cat.id}`}>
                  <div
                    className="banner_item align-items-center"
                    style={{
                      backgroundImage: `url(${BASE_URL_SHORT}/upload/${cat.image})`,
                      borderRadius: "8px",
                    }}
                    data-aos="fade-right"
                  ></div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      marginTop: "4px",
                      marginBottom: "15px",
                    }}
                  >
                    <span
                      style={{
                        color: "black",
                        fontSize: "16px",
                        fontWeight: 600,
                        textDecoration: "none",
                      }}
                    >
                      {props.lang == "en" ? `${cat.name}` : `${cat.name_a}`}
                    </span>
                    <span
                      style={{
                        color: "gray",
                        fontSize: "14px",
                        fontWeight: 400,
                        textDecoration: "none",
                      }}
                    >
                      {props.lang == "en"
                        ? `${cat.product_count} items`
                        : `${cat.product_count} منتج`}
                    </span>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default CategoryBanner;
