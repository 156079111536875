export const setCurrency = (currency) => (dispatch) => {
  dispatch({
    type: SET_Currency,
    payload: currency,
  });
  localStorage.setItem("currency", currency);
};
export const getCurrency = () => (dispatch) => {
  dispatch({
    type: GET_Currency,
  });
  return localStorage.getItem("currency");
};
export const SET_Currency = "SET_Currency";
export const GET_Currency = "GET_Currency";
