import React, { Component } from "react";
import "./style.css";
class InfoCard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div class="card border-0">
        <div class="card-header">
          <h5
            class="card-title"
            style={{
              color: "#3A94EE",
              fontSize: "17px",
              fontWeight: 500,
              textAlign: this.props.lang === "en" ? "left" : "right",
            }}
          >
            {this.props.title}
          </h5>
        </div>
        <div class="card-body">
          <table class="table">
            <tbody>
              {this.props.elements.map((element) => (
                <tr>
                  <td
                    style={{
                      fontSize: "14px",
                      fontWeight: 500,
                      width: "50%",
                      textAlign: this.props.lang === "en" ? "left" : "right",
                    }}
                  >
                    {element.title}
                  </td>
                  <td
                    style={{
                      fontSize: "13px",
                      fontWeight: 400,
                      width: "50%",
                      textAlign: this.props.lang === "en" ? "left" : "right",
                      color: "gray",
                    }}
                  >
                    {element.value}
                    {!element.value &&
                      (this.props.lang === "en"
                        ? "No info applied"
                        : "لا يوجد معلومات متوفرة")}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default InfoCard;
